import React from 'react'

function SendIcon() {
  return (
    <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_ddddd_3472_52003)">
    <rect x="8" y="8" width="37" height="37" rx="12" fill="#5B1DEE"/>
    <rect x="8" y="8" width="37" height="37" rx="12" fill="url(#paint0_linear_3472_52003)" fill-opacity="0.18"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2517 26.7836L24.6952 32.7258C24.8551 33.3842 25.3977 33.8802 26.0677 33.9806C26.7377 34.081 27.4019 33.7659 27.7478 33.1834L34.1804 22.3507C34.4863 21.8356 34.4922 21.1959 34.1959 20.6752C33.8995 20.1545 33.3465 19.833 32.7474 19.833H20.2533C19.5766 19.833 18.967 20.2422 18.7107 20.8685C18.4545 21.4949 18.6024 22.214 19.085 22.6883L23.2517 26.7836Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M34.1939 20.6729L23.2517 26.7838" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <filter id="filter0_ddddd_3472_52003" x="-9.53674e-07" y="-9.53674e-07" width="53" height="53" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="-2"/>
    <feGaussianBlur stdDeviation="3"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.992157 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3472_52003"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="2" dy="2"/>
    <feGaussianBlur stdDeviation="3"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.733333 0 0 0 0 0.764706 0 0 0 0 0.807843 0 0 0 0.6 0"/>
    <feBlend mode="normal" in2="effect1_dropShadow_3472_52003" result="effect2_dropShadow_3472_52003"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-4" dy="-4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="effect2_dropShadow_3472_52003" result="effect3_dropShadow_3472_52003"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-2" dy="-2"/>
    <feGaussianBlur stdDeviation="3"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.992157 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0"/>
    <feBlend mode="normal" in2="effect3_dropShadow_3472_52003" result="effect4_dropShadow_3472_52003"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="2" dy="2"/>
    <feGaussianBlur stdDeviation="3"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.733333 0 0 0 0 0.764706 0 0 0 0 0.807843 0 0 0 0.6 0"/>
    <feBlend mode="normal" in2="effect4_dropShadow_3472_52003" result="effect5_dropShadow_3472_52003"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect5_dropShadow_3472_52003" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_3472_52003" x1="26.5" y1="8" x2="26.5" y2="31.8958" gradientUnits="userSpaceOnUse">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </linearGradient>
    </defs>
    </svg>
    
  )
}

export default SendIcon