import React from 'react'

function GasIcon() {
  return (
    <div> <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0625 4.85375L13.8538 3.64625C13.7599 3.55243 13.6327 3.49972 13.5 3.49972C13.3673 3.49972 13.2401 3.55243 13.1462 3.64625C13.0524 3.74007 12.9997 3.86732 12.9997 4C12.9997 4.13268 13.0524 4.25993 13.1462 4.35375L14.3538 5.5625C14.447 5.65589 14.4996 5.78238 14.5 5.91437V11C14.5 11.1326 14.4473 11.2598 14.3536 11.3536C14.2598 11.4473 14.1326 11.5 14 11.5C13.8674 11.5 13.7402 11.4473 13.6464 11.3536C13.5527 11.2598 13.5 11.1326 13.5 11V8.5C13.5 8.10218 13.342 7.72064 13.0607 7.43934C12.7794 7.15804 12.3978 7 12 7H11V4C11 3.60218 10.842 3.22064 10.5607 2.93934C10.2794 2.65804 9.89782 2.5 9.5 2.5H4.5C4.10218 2.5 3.72064 2.65804 3.43934 2.93934C3.15804 3.22064 3 3.60218 3 4V13.5H2C1.86739 13.5 1.74021 13.5527 1.64645 13.6464C1.55268 13.7402 1.5 13.8674 1.5 14C1.5 14.1326 1.55268 14.2598 1.64645 14.3536C1.74021 14.4473 1.86739 14.5 2 14.5H12C12.1326 14.5 12.2598 14.4473 12.3536 14.3536C12.4473 14.2598 12.5 14.1326 12.5 14C12.5 13.8674 12.4473 13.7402 12.3536 13.6464C12.2598 13.5527 12.1326 13.5 12 13.5H11V8H12C12.1326 8 12.2598 8.05268 12.3536 8.14645C12.4473 8.24021 12.5 8.36739 12.5 8.5V11C12.5 11.3978 12.658 11.7794 12.9393 12.0607C13.2206 12.342 13.6022 12.5 14 12.5C14.3978 12.5 14.7794 12.342 15.0607 12.0607C15.342 11.7794 15.5 11.3978 15.5 11V5.91437C15.5008 5.71745 15.4625 5.52233 15.3874 5.34028C15.3123 5.15824 15.2019 4.99288 15.0625 4.85375ZM4 13.5V4C4 3.86739 4.05268 3.74021 4.14645 3.64645C4.24021 3.55268 4.36739 3.5 4.5 3.5H9.5C9.63261 3.5 9.75979 3.55268 9.85355 3.64645C9.94732 3.74021 10 3.86739 10 4V13.5H4ZM9 7.5C9 7.63261 8.94732 7.75979 8.85355 7.85355C8.75979 7.94732 8.63261 8 8.5 8H5.5C5.36739 8 5.24021 7.94732 5.14645 7.85355C5.05268 7.75979 5 7.63261 5 7.5C5 7.36739 5.05268 7.24021 5.14645 7.14645C5.24021 7.05268 5.36739 7 5.5 7H8.5C8.63261 7 8.75979 7.05268 8.85355 7.14645C8.94732 7.24021 9 7.36739 9 7.5Z"
      fill="#959595"
    />
  </svg></div>
  )
}

export default GasIcon