import React from 'react'

function Logo() {
  return (
    <div className='h-full w-full'><svg className='h-full w-full' width="98" height="32" viewBox="0 0 98 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3471_48568)">
    <path d="M53.6667 12.8704C51.9346 12.8704 50.6233 11.4763 50.6233 9.6408C50.6233 7.83698 51.9346 6.45867 53.6667 6.45867C54.633 6.45867 55.6939 7.00641 56.4127 7.88454L58.0189 5.97212C56.9262 4.84275 55.2411 4.12305 53.6192 4.12305C50.297 4.12305 47.7852 6.50622 47.7852 9.67245C47.7852 12.8862 50.2338 15.3147 53.5271 15.3147C55.1353 15.3147 56.8654 14.5158 58.037 13.3072L56.4151 11.5826C55.6493 12.368 54.5879 12.8704 53.6667 12.8704Z" fill="#323260"/>
    <path d="M63.1646 15.2833C65.9105 15.2833 67.7534 13.5587 67.7534 10.956C67.7534 8.35328 65.913 6.64453 63.1646 6.64453C60.4029 6.64453 58.5625 8.35328 58.5625 10.956C58.5625 13.561 60.4029 15.2833 63.1646 15.2833ZM63.1646 8.79459C64.273 8.79459 65.0369 9.68857 65.0369 10.9899C65.0369 12.3071 64.273 13.1852 63.1646 13.1852C62.0405 13.1852 61.2771 12.3071 61.2771 10.9899C61.2771 9.68857 62.043 8.79459 63.1646 8.79459Z" fill="#323260"/>
    <path d="M72.5929 15.298C73.6699 15.298 74.5279 14.8589 75.1208 14.0758V15.1893H77.8192V3.55859H75.1208V7.85422C74.5279 7.07111 73.6386 6.63207 72.5302 6.63207C70.2051 6.63207 68.6602 8.34077 68.6602 10.9276C68.6621 13.5439 70.2213 15.298 72.5929 15.298ZM73.2485 8.79344C74.3726 8.79344 75.1208 9.68743 75.1208 10.9887C75.1208 12.2901 74.3726 13.1999 73.2485 13.1999C72.1245 13.1999 71.3767 12.2901 71.361 10.9887C71.3767 9.68743 72.1245 8.79344 73.2485 8.79344Z" fill="#323260"/>
    <path d="M83.7959 15.2829C85.3414 15.2829 86.6051 14.733 87.4631 13.7938L86.044 12.3996C85.4835 12.9631 84.7646 13.2619 84.0326 13.2619C83.0345 13.2619 82.3161 12.6984 82.0501 11.6935H87.9634C88.1505 8.60646 86.684 6.64648 83.7195 6.64648C81.1132 6.64648 79.3359 8.37109 79.3359 10.9896C79.3335 13.5607 81.05 15.2829 83.7959 15.2829ZM83.7489 8.52725C84.7152 8.52725 85.3708 9.20166 85.3708 10.2359H82.0006C82.1721 9.21751 82.8115 8.52725 83.7489 8.52725Z" fill="#323260"/>
    <path d="M95.3044 3.55859V7.85422C94.712 7.07111 93.8222 6.63207 92.7138 6.63207C90.3892 6.63207 88.8438 8.34077 88.8438 10.9276C88.8438 13.5462 90.4049 15.3002 92.775 15.3002C93.8516 15.3002 94.71 14.8611 95.3024 14.0781V15.1916H98.0009V3.56086H95.3044V3.55859ZM93.4326 13.1977C92.3085 13.1977 91.5608 12.2878 91.5451 10.9865C91.5608 9.68516 92.3085 8.79118 93.4326 8.79118C94.5567 8.79118 95.3044 9.68516 95.3044 10.9865C95.3044 12.2878 94.5567 13.1977 93.4326 13.1977Z" fill="#323260"/>
    <path d="M50.6035 23.3373H56.1111V22.131H50.6035V18.5256H56.7667V17.3193H49.3555V28.2914H56.9696V27.0987H50.6035V23.3373Z" fill="#323260"/>
    <path d="M59.9269 22.1309C59.9269 21.3637 60.6296 21.0332 61.5488 21.0332C62.3122 21.0332 63.1389 21.3003 63.7788 21.7077L64.2473 20.7368C63.5603 20.266 62.6097 19.9854 61.6253 19.9854C60.1273 19.9854 58.771 20.644 58.771 22.2259C58.7553 25.1568 63.3574 24.1384 63.3104 26.13C63.2942 26.9448 62.5151 27.3069 61.5464 27.3069C60.5801 27.3069 59.5188 26.9154 58.8161 26.2568L58.3477 27.1824C59.1272 27.9338 60.3297 28.3412 61.4988 28.3412C63.0737 28.3412 64.5109 27.6215 64.5109 25.9897C64.5128 23.0249 59.9269 23.9641 59.9269 22.1309Z" fill="#323260"/>
    <path d="M69.1562 27.1623C68.4531 27.1623 68.0636 26.7866 68.0636 25.7817V21.2824H70.6699V20.2799L68.0636 20.2957V18.1162H66.863V20.2957H65.6758V21.2847H66.863V26.0035C66.863 27.6331 67.7529 28.355 68.9534 28.355C69.609 28.3392 70.2328 28.1197 70.8095 27.7281L70.467 26.7391C70.0142 27.022 69.5772 27.1623 69.1562 27.1623Z" fill="#323260"/>
    <path d="M75.7345 19.9834C74.3307 19.9834 73.4413 20.3908 72.38 21.0969L72.896 21.9909C73.7544 21.3956 74.5968 21.0494 75.4998 21.0494C76.9507 21.0494 77.7009 21.7691 77.7009 22.9777V23.4643H75.002C73.067 23.4801 71.9297 24.3876 71.9297 25.8316C71.9297 27.2574 73.0224 28.3551 74.7227 28.3551C76.0339 28.3551 77.0791 27.916 77.7347 27.022V28.2917H78.922L78.9058 22.8373C78.8882 21.0652 77.7641 19.9834 75.7345 19.9834ZM77.7166 25.4378C77.4035 26.583 76.3426 27.2868 74.9549 27.2868C73.7995 27.2868 73.067 26.6916 73.067 25.7818C73.067 24.872 73.7858 24.4487 75.1578 24.4487H77.7166V25.4378Z" fill="#323260"/>
    <path d="M83.9336 27.1623C83.2304 27.1623 82.8409 26.7866 82.8409 25.7817V21.2824H85.4472V20.2799L82.8409 20.2957V18.1162H81.6404V20.2957H80.4531V21.2847H81.6404V26.0035C81.6404 27.6331 82.5302 28.355 83.7307 28.355C84.3864 28.3392 85.0101 28.1197 85.5868 27.7281L85.2443 26.7391C84.7916 27.022 84.3545 27.1623 83.9336 27.1623Z" fill="#323260"/>
    <path d="M90.42 19.9991C88.0323 19.9991 86.3477 21.7079 86.3477 24.1702C86.3477 26.6304 88.0166 28.3391 90.4362 28.3391C91.8102 28.3391 92.9636 27.8389 93.7452 26.9608L93.0739 26.2411C92.4339 26.9156 91.5441 27.2754 90.5308 27.2754C88.9402 27.2754 87.7691 26.2569 87.5506 24.641H94.2612C94.3846 21.8323 93.0739 19.9833 90.42 19.9991ZM87.5506 23.6361C87.7373 22.0677 88.8614 21.0493 90.4225 21.0493C92.0307 21.0493 93.0896 22.0519 93.1685 23.6361H87.5506Z" fill="#323260"/>
    <path d="M15.2291 30.1453L20.8246 1.28466C20.9868 0.451791 21.6693 -0.114014 22.3496 0.019516C22.933 0.132677 23.325 0.723377 23.325 1.41366C23.325 1.52909 23.3137 1.64903 23.2912 1.76898L17.6957 30.6296C17.5335 31.4625 16.851 32.0283 16.1707 31.8947C15.5873 31.7816 15.1953 31.1909 15.1953 30.5006C15.1953 30.3852 15.2065 30.2653 15.2291 30.1453Z" fill="url(#paint0_linear_3471_48568)"/>
    <path d="M17.7979 5.88147C17.674 6.52422 17.1131 6.98817 16.4621 6.98817H10.5715L10.5468 6.99044L2.78423 15.9551L10.5603 24.9355L10.5715 24.9242H12.4547C13.3107 24.9242 13.955 25.7096 13.7905 26.5538C13.6666 27.1965 13.1057 27.6605 12.4547 27.6605H10.5715C9.84165 27.6605 9.13208 27.3708 8.62527 26.8638L0.727596 17.7476C0.259053 17.2587 0 16.625 0 15.9551C0 15.2852 0.256801 14.6515 0.727596 14.1649L8.5577 5.12105L8.62753 5.04637C9.13659 4.54165 9.84616 4.25195 10.5738 4.25195H16.4643C17.3181 4.25195 17.9624 5.03731 17.7979 5.88147Z" fill="url(#paint1_linear_3471_48568)"/>
    <path d="M29.8937 5.04863L32.4369 7.98402H36.1942C36.6988 7.98402 37.1088 8.39593 37.1088 8.90291V9.9847C37.1088 10.4917 36.6988 10.9036 36.1942 10.9036H34.9643L37.7913 14.1672C38.2599 14.656 38.5189 15.2897 38.5189 15.9573C38.5189 16.6273 38.2621 17.261 37.7913 17.7476L29.9612 26.7891L29.8914 26.8638C29.3824 27.3708 28.6728 27.6605 27.9429 27.6605H22.0546C21.1986 27.6605 20.5543 26.8751 20.7165 26.031C20.8404 25.3882 21.4013 24.9242 22.0546 24.9242L27.9699 24.922L35.7325 15.9573L27.9587 6.9769L27.9452 6.98817H26.062C25.206 6.98817 24.5617 6.20285 24.7262 5.35869C24.8501 4.71592 25.411 4.25195 26.062 4.25195H27.9452C28.675 4.25195 29.3846 4.54392 29.8937 5.04863Z" fill="url(#paint2_linear_3471_48568)"/>
    <path d="M27.603 13.3001L29.5267 15.5203C29.6416 15.6403 29.7046 15.7942 29.7046 15.9571C29.7046 16.1201 29.6416 16.274 29.5267 16.3939L27.6187 18.596L27.603 18.6141C27.479 18.7364 27.3056 18.8088 27.1277 18.8088H25.8121C25.4967 18.8088 25.2422 18.553 25.2422 18.2362V13.6781C25.2422 13.3612 25.4967 13.1055 25.8121 13.1055H27.1277C27.3056 13.1055 27.479 13.1756 27.603 13.3001Z" fill="url(#paint3_linear_3471_48568)"/>
    <path d="M11.9139 18.6001L9.57791 17.5545C9.48103 17.5115 9.41797 17.4142 9.41797 17.3078V14.6032C9.41797 14.4969 9.48103 14.3996 9.57791 14.3566L11.9139 13.3109C12.0625 13.2453 12.2292 13.2453 12.3779 13.3109L14.7139 14.3566C14.8107 14.3996 14.8738 14.4969 14.8738 14.6032V17.3078C14.8738 17.4142 14.8107 17.5115 14.7139 17.5545L12.3779 18.6001C12.2292 18.668 12.0603 18.668 11.9139 18.6001Z" fill="url(#paint4_linear_3471_48568)"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_3471_48568" x1="-8.64348" y1="32.5788" x2="41.158" y2="3.18978" gradientUnits="userSpaceOnUse">
    <stop offset="2.29095e-07" stop-color="#6317F7"/>
    <stop offset="1" stop-color="#18FFFF"/>
    </linearGradient>
    <linearGradient id="paint1_linear_3471_48568" x1="-10.7109" y1="29.0781" x2="39.0907" y2="-0.31096" gradientUnits="userSpaceOnUse">
    <stop offset="2.29095e-07" stop-color="#6317F7"/>
    <stop offset="1" stop-color="#18FFFF"/>
    </linearGradient>
    <linearGradient id="paint2_linear_3471_48568" x1="-6.57967" y1="36.08" x2="43.2219" y2="6.69098" gradientUnits="userSpaceOnUse">
    <stop offset="2.29095e-07" stop-color="#6317F7"/>
    <stop offset="1" stop-color="#18FFFF"/>
    </linearGradient>
    <linearGradient id="paint3_linear_3471_48568" x1="-6.76411" y1="35.7631" x2="43.0374" y2="6.37411" gradientUnits="userSpaceOnUse">
    <stop offset="2.29095e-07" stop-color="#6317F7"/>
    <stop offset="1" stop-color="#18FFFF"/>
    </linearGradient>
    <linearGradient id="paint4_linear_3471_48568" x1="-10.4953" y1="29.443" x2="39.3062" y2="0.0539963" gradientUnits="userSpaceOnUse">
    <stop offset="2.29095e-07" stop-color="#6317F7"/>
    <stop offset="1" stop-color="#18FFFF"/>
    </linearGradient>
    <clipPath id="clip0_3471_48568">
    <rect width="98" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    </div>
  )
}

export default Logo