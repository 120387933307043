import React from "react";
import "./BouncingDotsLoader.css"; 

const BouncingDotsLoader = () => {
  return (
    <div className="bouncing-loader mt-10">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default BouncingDotsLoader;

