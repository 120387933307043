import React, { useEffect, useRef, useState } from "react";
import Chatboticon from "./chatboticon";
import SendIcon from "./sendIcon";
// import Stars from "./stars";
import { WelcomeScreen } from "./WelcomeScreen";
import MinusIcon from "./minusicon";
import HotelTable from "./HotelTable";
import ComparisonTable from "./ComparisonTable";
import Message from "./Message";
import Unavailable from "./Unavilable"
import BouncingDotsLoader from "./BouncingDotsLoader";

 const DynamicTextArea = ({ value, onChange, onKeyPress }) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
  
      textAreaRef.current.style.height = 'auto';

      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={textAreaRef}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      rows={1}
      className="flex-grow rounded-lg border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-purple-600 resize-none overflow-hidden min-h-[40px] max-h-[200px]"
      placeholder="Type a message..."
      style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
    />
  );
};





const Chatbot = () => {
  const [isOpened, setIsOpened] = useState(true);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showIcon, setShowIcon] = useState(true);
  const [showWelcome, setShowWelcome] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
    const [currentTime,setCurrentTime] = useState('');
    const [isEntering, setIsEntering] = useState(true);
    const [completedMessages, setCompletedMessages] = useState(new Set());
    const [chatHistory, setChatHistory] = useState([]);
  const messagesEndRef = useRef(null);
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, completedMessages]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpened(false);
      setIsClosing(false);
      setShowIcon(true);
    }, 300); 
  };
  

    useEffect(() => {
      const updateTime = () => {
        const now = new Date();
        setCurrentTime(now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
      };
      updateTime();
      const timer = setInterval(updateTime, 1000); 
      return () => clearInterval(timer); 
    }, []);

  const sendMessage = async (text) => {
    setIsLoading(true);
    const Api = process.env.REACT_APP_BACKEND_SERVER;
    // const Api = "https://chatbot-7x43.onrender.com";
    
    try {
      const response = await fetch(`${Api}chat/samplechat`, {
      // const response = await fetch(`http://localhost:8080/chat/samplechat`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: text }),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      
      const data = await response.json();
      console.log(data.response)

       const cleanedResponseText = data.response.replace(/(^"|"$)/g, '').trim();
    setTimeout(()=>{
      setMessages(prev => [...prev, {
        type: 'response',
        text: cleanedResponseText,
        intent: data.intent,
        results: data.results,
      }]);

    },1000)
 
    } catch (error) {
      setMessages(prev => [...prev, {
        type: 'error',
        text: 'Unable to fetch response. Please try again.'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend =async () => {
    if (input.trim()) {
      setMessages(prev => [...prev, { type: 'query', text: input }]);
     const currentInput = input;
     setInput('');
      await sendMessage(currentInput)
    }
  };

  const handleWelcomeMessage = async (text) => {
    if (text === 'Comparison Price') {
      const comparisons = [
        "compare palm jumeirah",
        "compare Jumeirah Village Circle",
        "compare The Torch Tower- Dubai Marina",
        "compare Luxury Apt with Burj Khalifa",
        "compare Deluxe Apartment With Direct",
        "compare Fancy 1BR Apt at Dubai Hills",
        "compare Breathtaking Apt With Partial",
        "compare Forte Tower 1 - Downtown",
        "compare Al Fahad Tower2 - Barsha Heights",
        "compare DAMAC Merano Tower-Business Bay"
      ];
      const randomIndex = Math.floor(Math.random() * comparisons.length);
      const chosenComparison = comparisons[randomIndex];
  
      setMessages(prev => [...prev, { type: 'query', text }]);
      setShowWelcome(false);
      setIsEntering(true);
  
      await sendMessage(chosenComparison);
      return;
    }
  
    setMessages(prev => [...prev, { type: 'query', text }]);
    setShowWelcome(false);
    setIsEntering(true);
    await sendMessage(text);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };
  // console.log(showConfirmation)

  const handleTypeWriterComplete = (messageId) => {
    setCompletedMessages(prev => new Set([...prev, messageId]));
  };

  // Store chat history and completed messages in localStorage when minimizing
  const handleMinimize = () => {
    localStorage.setItem('chatHistory', JSON.stringify(messages));
    localStorage.setItem('completedMessages', JSON.stringify([...completedMessages]));
    setIsOpened(false);
  };

  // Restore chat history and completed messages when opening
  useEffect(() => {
    if (isOpened) {
      const savedHistory = localStorage.getItem('chatHistory');
      const savedCompleted = localStorage.getItem('completedMessages');
      
      if (savedHistory) {
        setMessages(JSON.parse(savedHistory));
      }
      if (savedCompleted) {
        setCompletedMessages(new Set(JSON.parse(savedCompleted)));
      }
    }
  }, [isOpened]);

  return (
    <div className="fixed right-4 bottom-10 z-50">
      {showIcon && (
         <button
         className="w-14 h-14 "
         onClick={() => setIsOpened(!isOpened)}
        //  onClick={handleOpenChatbot}
         
       >
         <Chatboticon/>
       </button>
      )}
     



{isOpened && (
 <div className="absolute bottom-10 right-2 w-[410px]  transform scale-95  ">
    {showWelcome ? (
      <WelcomeScreen 
        onSendMessage={handleWelcomeMessage} 
        onClose={handleClose}
      />
    ) : (
      <div className={`absolute bottom-10 right-2 w-[410px] h-[550px] border border-purple-600 rounded-2xl bg-white shadow-lg overflow-hidden flex flex-col transition-all duration-300 ease-in-out ${
        isClosing ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
      }`}
      style={{ overflow: 'hidden' }}
      onAnimationEnd={()=>setIsEntering(false)}
      >
        <div className="bg-gradient-to-r from-[#6B349A] to-[#4C37C3] h-full flex flex-col">
          <div className="text-white p-3 py-4 flex justify-between items-start">
            <div className="flex items-center">
              <div className="w-10 h-10  rounded-full bg-white flex items-center justify-center">
                <Chatboticon />
              </div>
              <div className="ml-3">
                <div className="text-lg font-semibold">CodeAI</div>
                <div className="text-sm text-gray-300">You can ask anything</div>
              </div>
            </div>
            <div className="flex justify-between  w-14">
           <div> <button className="w-4 h-4 rounded-full bg-white/5 backdrop-blur-md " onClick={()=>setMessages([])}>
              <svg className="w-full h-full" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-refresh-cw"><path d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8"/><path d="M21 3v5h-5"/><path d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16"/><path d="M8 16H3v5"/></svg></button>
              </div><div><button
              className="w-6 h-6 rounded-full bg-white/5 backdrop-blur-md"
              onClick={handleMinimize}
            >
              <span className="text-white text-3xl flex justify-center items-center">
                <MinusIcon />
              </span>
            </button></div>
            </div>
          </div>

          <div className="p-4 flex-1 flex flex-col min-h-0"> 
            <div className="bg-white rounded-lg flex-1 flex flex-col min-h-0"> 
          
            <div className="flex-1 overflow-y-auto p-4" style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
            <div className="bg-white  flex-1 rounded-3xl p-4">
  <div className="flex justify-center items-center mb-6 relative">
    
    <div className="absolute top-1/2 left-0 right-0 h-[2px] bg-[#F6F6F6] transform -translate-y-1/2 z-0"></div>

  
    <div className="px-6 py-1 border-2 border-[#F6F6F6] bg-[#F6F6F6] text-gray-500 rounded-full text-gray-600 text-sm bg-white relative flex items-center z-10">
      Today: <span className="ml-2">{currentTime}</span>
    </div>
  </div>
  <div className="flex items-start gap-3 mb-2">
              <div className="w-6 h-6 rounded-full flex justify-center mt-14 items-center text-xs">
                <Chatboticon className="h-6"/>
              </div>
              <div className="bg-white rounded-xl  shadow-lg p-4">
                <p className="text-gray-800 text-sm">Hi There, Coded-AI is here!</p>
                <p className="text-gray-800 text-sm">How can I help you today?</p>
              </div>
            </div>
  </div>
                {messages.map((msg, index) => {
                  const messageId = `msg-${index}`;
                  const isTypeWriterComplete = completedMessages.has(messageId);
                  
                  return (
                    <div 
                      key={messageId} 
                      className="mb-2 animate-fadeIn"
                      style={{ animationDelay: `${index * 0.1}s` }}
                    >
                      <Message 
                        message={msg} 
                        onTypeWriterComplete={() => handleTypeWriterComplete(messageId)} 
                        isMessageCompleted={completedMessages.has(messageId)}
                      />
                      
                      {msg.intent === "compare" && (
                        <div className="flex text-xs items-end">
                          <div className="w-full text-xs max-w-6 h-10"><Chatboticon /></div>
                          <div className="text-xs w-[300px]"><HotelTable data={msg} /></div>
                        </div>
                      )}
                      
                      {isTypeWriterComplete && (
                        <>
                          {msg.intent === "region_unavailable" && <Unavailable  />}
                          
                          {msg.intent !== "region_unavailable" && 
                           msg.intent !== "compare" && 
                           msg.results && (
                            <div className="text-sm w-[400px]">
                              <ComparisonTable data={msg.results} />
                            </div>
                          )}
                        </>
                      )}
                <div ref={messagesEndRef} />
                    </div>
                  );
                })}
                {isLoading && (
                <span className="mt-6">  <BouncingDotsLoader /> </span>
                )}
              </div>
              
              <div className="border-t border-gray-200 p-3 flex items-center space-x-2">
              <DynamicTextArea
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                <button 
                  onClick={handleSend} 
                  className="p-2 text-white rounded-lg hover:bg-purple-700 "
                >
                  <SendIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
)}
</div>
);
};

export default Chatbot;