import React from 'react'

function Approval4() {
  return (
    <div> <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99935 1.25V2.75"
      stroke="white"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.99935 7.25V8.75"
      stroke="white"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.34961 2.34998L3.40794 3.40831"
      stroke="white"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5918 6.59167L7.65013 7.65001"
      stroke="white"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 4.99996H2.75"
      stroke="white"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 4.99996H8.75"
      stroke="white"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.34961 7.65001L3.40794 6.59167"
      stroke="white"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5918 3.40831L7.65013 2.34998"
      stroke="white"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg></div>
  )
}

export default Approval4