import React from 'react'

function SellIcon() {
  return (
    <div> <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4H10C10.5525 4 11 4.4475 11 5V10C11 10.5525 10.5525 11 10 11H2C1.4475 11 1 10.5525 1 10V5C1 4.4475 1.4475 4 2 4Z"
      fill="white"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.88388 6.61612C7.37204 7.10427 7.37204 7.89573 6.88388 8.38388C6.39573 8.87204 5.60427 8.87204 5.11612 8.38388C4.62796 7.89573 4.62796 7.10427 5.11612 6.61612C5.60427 6.12796 6.39573 6.12796 6.88388 6.61612Z"
      fill="white"
    />
    <path
      d="M6.88388 6.61612C7.37204 7.10427 7.37204 7.89573 6.88388 8.38388C6.39573 8.87204 5.60427 8.87204 5.11612 8.38388C4.62796 7.89573 4.62796 7.10427 5.11612 6.61612C5.60427 6.12796 6.39573 6.12796 6.88388 6.61612"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8.75V6.25V8.75Z"
      fill="white"
    />
    <path
      d="M9 8.75V6.25"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 8.75V6.25V8.75Z"
      fill="white"
    />
    <path
      d="M3 8.75V6.25"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 2.5H2"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 1H2.5"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg></div>
  )
}

export default SellIcon