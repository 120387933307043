import React from 'react'

function SellBuyMiddleIcon() {
  return (
    <div>  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.58284 16.233C9.40654 18.9994 10.3184 20.3826 11.9223 20.3923C13.5262 20.4019 14.4749 18.9998 16.3722 16.1955C16.9606 15.3258 17.511 14.4534 18.0404 13.5397C19.6054 10.8387 20.3879 9.48821 19.6445 8.03308C18.901 6.57796 17.4077 6.4267 14.4211 6.12416C12.826 5.96259 11.2887 5.95871 9.69645 6.11251C6.6629 6.40554 5.14612 6.55205 4.39356 7.99316C3.641 9.43427 4.43052 10.8392 6.00954 13.649C6.5129 14.5447 7.02978 15.3941 7.58284 16.233Z"
      fill="#5B1DEE"
    />
    <path
      d="M7.58284 16.233C9.40654 18.9994 10.3184 20.3826 11.9223 20.3923C13.5262 20.4019 14.4749 18.9998 16.3722 16.1955C16.9606 15.3258 17.511 14.4534 18.0404 13.5397C19.6054 10.8387 20.3879 9.48821 19.6445 8.03308C18.901 6.57796 17.4077 6.4267 14.4211 6.12416C12.826 5.96259 11.2887 5.95871 9.69645 6.11251C6.6629 6.40554 5.14612 6.55205 4.39356 7.99316C3.641 9.43427 4.43052 10.8392 6.00954 13.649C6.5129 14.5447 7.02978 15.3941 7.58284 16.233Z"
      fill="url(#paint0_linear_2615_14727)"
      fillOpacity="0.18"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2615_14727"

        x1="12"
        y1="22"
        x2="12"
        y2="11.6667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg></div>
  )
}

export default SellBuyMiddleIcon