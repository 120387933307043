import React from 'react'

function LightLongIcon() {
  return (
    <div><svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9585 1.375L1.7085 9.625"
      stroke="#959595"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16699 1.33331L9.95866 1.37415L10.0003 7.16665"
      stroke="#959595"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg></div>
  )
}

export default LightLongIcon