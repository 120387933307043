import React from 'react'

function Approal2() {
  return (
    <div> <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.29102 5.08329L4.37435 7.16663L8.12435 3.41663"
      stroke="white"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg></div>
  )
}

export default Approal2