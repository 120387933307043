import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget() {
  const container = useRef(null);
  const scriptRef = useRef(null);

  useEffect(() => {
    // Ensure we only append the script once
    if (container.current && !scriptRef.current) {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = JSON.stringify({
        height: "500",
        autosize: true,
        symbol: "BITSTAMP:BTCUSD",
        interval: "D",
        timezone: "Etc/UTC",
        theme: "light",
        style: "1",
        locale: "en",
        hide_legend: true,
        hide_top_toolbar: true,
        allow_symbol_change: true,
        calendar: false,
        support_host: "https://www.tradingview.com"
      });

      // Store the script reference to prevent re-rendering
      scriptRef.current = script;
      container.current.appendChild(script);
    }

    // Cleanup function to remove the script if component unmounts
    return () => {
      if (scriptRef.current && container.current) {
        container.current.removeChild(scriptRef.current);
        scriptRef.current = null;
      }
    };
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div 
      ref={container} 
      className="tradingview-widget-container"
      style={{ 
        width: '100%', 
        height: '500px' 
      }}
    >
      <div 
        className="tradingview-widget-container__widget"
        style={{ 
          height: '100%', 
          width: '100%' 
        }}
      ></div>
    </div>
  );
}

export default memo(TradingViewWidget);