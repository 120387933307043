export const api = async (endpoint, params = {}) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers like authorization if needed
        },
        body: JSON.stringify(params)
      });
  
      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorBody}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('API call error:', error);
      throw error;
    }
  };