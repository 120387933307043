import { useNavigate,useLocation } from "react-router-dom";
import { useState,useEffect } from "react";
import { SelectionGroup,SelectionItem } from "../Selection/Selection";
import { LightIcon } from "../../AssetComponents/Images";

export const RentalNavigation = () => {
  const [current, setCurrent] = useState(-1);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname.split("/")[2]) {
      case "all":
        setCurrent(0);
        break;
      case "long":
        setCurrent(1);
        break;
      case "short":
        setCurrent(2);
        break;
      default:
        setCurrent(-1);
        break;
    }
  }, [location]);

  return (
    <SelectionGroup
      defaultItem={current}
      className="border-[2px] border-[#e3e3e3] w-max px-[6px] py-[4px] gap-[8px] flex items-center rounded-[14px] bg-white shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
    >
      <SelectionItem
        SelectedItem={
          <div className="py-[4px] cursor-pointer bg-white hover:bg-[#f6f6f6] rounded-[10px] w-[120px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
            <div className="text-black font-semibold">All</div>
            <LightIcon />
          </div>
        }
        UnselectedItem={
          <div
            onClick={() => navigate("/rent/all")}
            className="py-[4px] cursor-pointer hover:bg-[#f6f6f6] rounded-[10px] w-[120px] flex justify-center"
          >
            <div className="text-[#959595]">All</div>
          </div>
        }
      />
      <SelectionItem
        SelectedItem={
          <div className="py-[4px] cursor-pointer bg-white hover:bg-[#f6f6f6] rounded-[10px] w-[120px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
            <div className="text-black font-semibold">Long term</div>
            <LightIcon />
          </div>
        }
        UnselectedItem={
          <div
            onClick={() => navigate("/rent/long")}
            className="py-[4px] cursor-pointer hover:bg-[#f6f6f6] rounded-[10px] w-[120px] flex justify-center"
          >
            <div className="text-[#959595]">Long term</div>
          </div>
        }
      />
      <SelectionItem
        SelectedItem={
          <div className="py-[4px] cursor-pointer bg-white hover:bg-[#f6f6f6] rounded-[10px] w-[120px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
            <div className="text-black font-semibold">Short term</div>
            <LightIcon />
          </div>
        }
        UnselectedItem={
          <div
            onClick={() => navigate("/rent/short")}
            className="py-[4px] cursor-pointer hover:bg-[#f6f6f6] rounded-[10px] w-[120px] flex justify-center"
          >
            <div className="text-[#959595]">Short term</div>
          </div>
        }
      />
    </SelectionGroup>
  );
};