import React from 'react'

function Swap2() {
  return (
    <div> 
     <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5859 6.49419L13.0001 4.08085L10.5859 1.66669"
      stroke="#959595"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33398 4.08335H13.0007"
      stroke="#959595"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.74815 8.50586L1.33398 10.9192L3.74815 13.3334"
      stroke="#959595"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0007 10.9167H1.33398"
      stroke="#959595"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg></div>
  )
}

export default Swap2