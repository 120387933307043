import Chatboticon from "./chatboticon";
import TypeWriter from "./TypeWriter";
import { useState } from "react";
import Unavailable from "./Unavilable";

const Message = ({ message, onTypeWriterComplete, isMessageCompleted }) => {
    const isQuery = message.type === "query";
  
    // Function to convert markdown-style links to JSX
    const parseLinks = (text) => {
      const parts = [];
      const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
      let lastIndex = 0;
      let match;
  
      while ((match = linkRegex.exec(text)) !== null) {
        if (match.index > lastIndex) {
          parts.push(text.slice(lastIndex, match.index));
        }
  
        parts.push(
          <a
            key={match.index}
            href={match[2]}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            {match[1]}
          </a>
        );

        console.log('====================================');
        console.log(parts);
        console.log('====================================');
  
        lastIndex = match.index + match[0].length;
      }
  
      // Add remaining text
      if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
      }
  
      return parts;
    };

    const renderContent = (text) => {
      return text.split("\n").map((line, i) => (
        <p key={i} className="text-sm leading-relaxed">
          {parseLinks(line.replaceAll("**", ""))}
        </p>
      ));
    };
  
    return (
      <div className={`flex ${isQuery ? "justify-end" : "justify-start"} mb-4`}>
        {!isQuery && (
          <div className="w-8 h-8 rounded-full flex items-center justify-center mr-2 self-end mb-2">
            <div className="w-6 h-6">
              {message.intent != 'compare' ? <Chatboticon /> : null}
            </div>
          </div>
        )}
        <div
          className={`max-w-[80%] rounded-2xl  text-sm p-4 ${
            isQuery
              ? "text-white bg-purple-500   rounded-br-none"
              : "bg-white shadow-md text-black rounded-bl-none"
          }`}
        >
          {message.intent === "region_unavailable" ? (
            <Unavailable />
          ) : (
            <>
              {message.intent && (
                <div className="text-sm font-semibold mb-2 text-sm text-gray-800">
                  {message.intent === "compare" && message.results ? (
                    <div className="text-md leading-relaxed">Sure, This is comparison with Airbnb</div>
                  ) : (
                    <span className={`${isQuery ? "text-white" : "text-purple-600"}`} />
                  )}
                </div>
              )}
              {message.intent !== "compare" && (
                <div className="space-y-2">
                  {isQuery ? (
                    message.text.split("\n").map((line, i) => (
                      <p key={i} className="text-sm leading-relaxed">
                        {parseLinks(line.replaceAll("**", ""))}
                      </p>
                    ))
                  ) : (
                    <TypeWriter 
                      text={message.text} 
                      speed={10}
                      renderHTML={(text) => renderContent(text)}
                      onComplete={() => onTypeWriterComplete && onTypeWriterComplete(message.id)}
                      isRestarting={false}
                      isMessageCompleted={isMessageCompleted}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  export default Message;