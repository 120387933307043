import React from 'react'

function Approval3() {
  return (
    <div>  <svg
    className="h-[18px]"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.89917 18.1733C8.15583 18.1733 9.98583 16.3433 9.98583 14.0867C9.98583 11.83 8.15667 10 5.89917 10C3.64167 10 1.8125 11.83 1.8125 14.0867"
      stroke="#A4A4A4"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.90185 18.1775C5.28685 18.1775 4.70435 18.0417 4.18102 17.7992L1.66602 18.3333L2.19185 15.8133C1.94768 15.2883 1.81102 14.7033 1.81102 14.0867"
      stroke="#A4A4A4"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.46417 10.0233C5.16583 9.33913 5 8.5933 5 7.8108C5 4.4633 7.99833 1.77747 11.6667 1.77747C15.335 1.77747 18.3333 4.4633 18.3333 7.8108C18.3333 9.78913 17.2817 11.5308 15.6675 12.6291C15.6683 13.2591 15.6667 14.1083 15.6667 15L13.0508 13.7116C12.6033 13.7975 12.1408 13.8441 11.6667 13.8441C11.0775 13.8441 10.5058 13.7741 9.96083 13.6433"
      stroke="#A4A4A4"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg></div>
  )
}

export default Approval3