import UAE from "../../assets/images/Perps/UAE.png";
import { Chart } from "./Chart";
import {
  SelectionGroup,
  SelectionItem,
} from "../../Components/Selection/Selection";
import {
  ArrowToDownHeader,
  ArrowToLeft,
  CloseIcon,
  LightIcon,
  NibiruIcon,
  NUSDIcon,
} from "../../AssetComponents/Images";
import InputSlider from "react-input-slider";
import { PureComponent, useEffect, useState, useRef } from "react";
import { BlackButton } from "../../Components/Buttons/BlackButton";
import Modal from "react-responsive-modal";
import { PurpleButton } from "../../Components/Buttons/PurpleButton";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Toggle } from "../../Components/Toggle/Toggle";
import { GreenButton } from "../../Components/Buttons/GreenButton";
import { useNavigate } from "react-router-dom";
import {
  executeContract,
  queryContract,
  executePerps
} from "../../Components/functions/Contract";
import { Testnet } from "@nibiruchain/nibijs";
import { useDispatch, useSelector } from "react-redux";
import TradingViewWidget from "./TradingView/TradingViewWidget";
import { toast } from "react-toastify";
import { RedButton } from "../../Components/Buttons/RedButton";
import Bitcoin from "../../Components/Trading/Bitcoin";
import LongIcon from "../../Components/Trading/LongIcon";
import DownIcon from "../../Components/Trading/DownIcon";
import Swap from "../../Components/Trading/Swap";
import Swap2 from "../../Components/Trading/Swap2";
import LightLongIcon from "../../Components/Trading/LightLongIcon";
import ShortDownIcon from "../../Components/Trading/ShortDownIcon";
import Settings from "../../Components/Trading/Settings";
import MarketIcon from "../../Components/Trading/MarketIcon";
import SellIcon from "../../Components/Trading/SellIcon";
import SellBuyMiddleIcon from "../../Components/Trading/SellBuyMiddleIcon";
import BuyIcon from "../../Components/Trading/BuyIcon";
import GasIcon from "../../Components/Trading/GasIcon";
import ApprovalIcon from "../../Components/Trading/ApprovalIcon";
import Approal2 from "../../Components/Trading/Approal2";
import Approval3 from "../../Components/Trading/Approval3";
import Approval4 from "../../Components/Trading/Approval4";
import Approal5 from "../../Components/Trading/Approal5";
import Slip from "../../Components/Trading/Slip";

export const Trading = () => {
  const [leverage, setLeverage] = useState(3);
  const [amount, setAmount] = useState(100);
  const navigate = useNavigate();
  
  // Values for managing positions
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedTradeIndex, setSelectedTradeIndex] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const diff = useSelector((state) => state.time.diffToUTC);

  const [tab, setTab] = useState(0);
  const [longShort, setLongShort] = useState(0);
  const [list, setList] = useState(0);
  const dispatch = useDispatch();
  const [modalFlag, setModalFlag] = useState(0);
  const account = useSelector((state) => state.auth.account);
  const open_price = useSelector((state) => state.perps.crypto.btc);
  const [leverage_value, setLeverageValue] = useState(1.1);
  const [tp, setTp] = useState(70000);
  const [sl, setSl] = useState(68000);
  const [isManualTPSL, setIsManualTPSL] = useState(false);
  // Your label values
  const labels = [1, 2, 5, 10, 25, 50, 100];

  const [activeTab, setActiveTab] = useState('position'); // ['position', 'size', 'limit', 'actions']
  const [modalInputs, setModalInputs] = useState({
  price: '',
  newTp: '',
  newSl: '',
  leverageDelta: '',
  collateralDelta: '',
  openPrice: '',
  orderType: ''
});

  // Mapping values to slider values
  const logScale = (val) => Math.log(val);
  const revLogScale = (val) => Math.exp(val);
  const handleChange = (val) => {
    const newValue = revLogScale(val).toFixed(1);
    setLeverageValue(newValue);
  };

  const [trades, setTrades] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [position, setPosition] = useState({
    size: 0,
    leverage: 1.1,
    collateral: 0,
    pnl: 0
  });

  const calculatePnL = (position) => {
    if (!position) return 0;
    const currentPrice = open_price;
    const openPrice = position.open_price;
    const size = Number(position.collateral_amount) / 1000000;
    const isLong = position.long;
    
    if (isLong) {
      return size * (currentPrice - openPrice) / openPrice;
    } else {
      return size * (openPrice - currentPrice) / openPrice;
    }
  };

  const calculateProfit = (position) => {
    const currentPrice = open_price;
    const openPrice = position.open_price;
    const size = Number(position.collateral_amount) / 1000000;
    const isLong = position.long;
  
    if (isLong) {
      return size * (currentPrice - openPrice) / openPrice;
    } else {
      return size * (openPrice - currentPrice) / openPrice;
    }
  };

 
  const REQUIRED_PARAMS = {
    MARKET_INDEX: "MarketIndex(0)",  
    COLLATERAL_INDEX: "TokenIndex(1)",  
    SLIPPAGE: "0.01", 
    MARKETS: {
      BTC: "MarketIndex(0)",
      ETH: "MarketIndex(1)"
    },
    COLLATERALS: {
      USDC: "TokenIndex(1)"
    },
    DECIMALS: 6,
    DENOM: "tf/nibi13vuql6pp0m84nl73aya4hnnwyj896hthkjkwe8crcl023qd2nzgqcmqhlj/utestate"
  };

  const PendingOrderType = {
    LIMIT_OPEN: "limit_open",
    STOP_OPEN: "stop_open"
  };

  const getMarkets = async () => {
    try {
      setIsLoading(true);
      const message = {
        get_trades: {
          trader: account,
        },
      };
  
      const res = await queryContract(
        process.env.REACT_APP_PERP_SMART_CONTRACT, 
        message,
        Testnet(1).endptTm
      );
  
      if (res) {
        setTrades(res);
        console.log(res)
         // Update position if exists
        if (res.length > 0) {
          const position = res[0];
          setPosition({
            size: Number(position.collateral_amount) / 1000000,
            leverage: position.leverage,
            collateral: Number(position.collateral_amount) / 1000000, 
            pnl: calculatePnL(position)
          });
        }
      }
  
    } catch (err) {
      console.error("Failed to fetch trades:", err);
      setError("Failed to fetch trades");
    } finally {
      setIsLoading(false);
    }
  };
  
  const getOis = async () => {
    const message = {
      get_borrowing_pair_oi: {
        collateral_index: 1,
        market_index: 0,
      },
    };

    const res = await queryContract(
      process.env.REACT_APP_PERP_SMART_CONTRACT,
      message,
      Testnet(1).endptTm
    );

    console.log(res);
  };

  const handleOpenTrade = async (openPrice) => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }
  
      const currentTime = new Date(
        (Math.floor(new Date().getTime() / 1000) + diff) * 1000
      );

      const marginAmount = (Number(amount) * Math.pow(10, REQUIRED_PARAMS.DECIMALS)).toString();
      
      const message = {
        open_trade: {
          market_index: REQUIRED_PARAMS.MARKET_INDEX,
          leverage: leverage_value.toString(),
          long: longShort === 0,
          collateral_index: REQUIRED_PARAMS.COLLATERAL_INDEX,
          trade_type: "trade",
          open_price: "69000",
          tp: tp ? tp.toString() : null,
          sl: sl ? sl.toString() : null,
          slippage_p: "0.01"
        }
      };
  
      const tokenToSend = [{
        amount: marginAmount,
        denom: REQUIRED_PARAMS.DENOM
      }];
  
      const testnet = Testnet(1);
      
      const res = await executePerps(
        null,
        null, 
        currentTime,
        testnet.chainId,
        testnet.endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        null,
        null,
        message,
        account,
        "leap",
        tokenToSend
      );
  
      if(!res) {
        throw new Error("Transaction failed");
      }
  
      await getMarkets(); // Refresh positions
      setShowModal(false);
      toast.success("Trade opened successfully!");
      
      return res;
  
    } catch (err) {
      console.error("Open trade error:", err);
      toast.error(`Failed to open trade: ${err.message}`);
      return false;
    }
  };

  const handleCloseTrade = async (tradeIndex) => {
    try {
      if (!account) {
        toast.error("Please connect wallet");
        return false;
      }

      const trade = trades.find(t => t.user_trade_index === tradeIndex);
      if (!trade) {
        throw new Error(`Trade not found with index ${tradeIndex}`);
      }

      const currentTime = new Date(
        (Math.floor(new Date().getTime() / 1000) + diff) * 1000
      );

      const testnet = Testnet(1);

      const message = {
        close_trade_market: {
          trade_index: trade.user_trade_index
        }
      };

      console.log("Close trade message:", message);

      const res = await executePerps(
        null,
        null, 
        currentTime,
        testnet.chainId,
        testnet.endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        null,
        null,
        message,
        account,
        "leap",
        null,
        { gasLimit: 1000000 }
      );

      if (!res) {
        throw new Error("Close trade transaction failed");
      }

      toast.success("Trade closed successfully");
      await getMarkets();
      return true;

    } catch (err) {
      console.error("Close trade error:", err);
      toast.error(`Failed to close trade: ${err.message}`);
      return false;
    }
  };

  const handleUpdateTp = async (tradeIndex, newTpValue) => {
    try {

      const currentTime = new Date((Math.floor(new Date().getTime() / 1000) + diff) * 1000);
      const testnet = Testnet(1);
      
      const message = {
        update_tp: {
          trade_index: tradeIndex,
          new_tp: newTpValue.toString()
        }
      };

      const res = await executePerps(
        null, 
        null, 
        currentTime, 
        testnet.chainId,
        testnet.endptTm, 
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch, 
        null, 
        null, 
        message, 
        account, 
        "leap"
      );

      if(res) {
        await getMarkets();
        toast.success("Take profit updated successfully");
      }
      return res;
    } catch (err) {
      console.error("Update TP error:", err);
      toast.error(`Failed to update take profit: ${err.message}`);
      return false;
    }
  };

  const handleUpdateSl = async (tradeIndex, newSlValue) => {
    try {
      const currentTime = new Date((Math.floor(new Date().getTime() / 1000) + diff) * 1000);
      const testnet = Testnet(1);
      
      const message = {
        update_sl: {
          trade_index: tradeIndex,
          new_sl: newSlValue.toString()
        }
      };

      const res = await executePerps(
        null, null, currentTime, testnet.chainId,
        testnet.endptTm, process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch, null, null, message, account, "leap"
      );

      if(res) {
        await getMarkets();
        toast.success("Stop loss updated successfully");
      }
      return res;
    } catch (err) {
      console.error("Update SL error:", err);
      toast.error(`Failed to update stop loss: ${err.message}`);
      console.log(err);
      return false;
    }
  };

  const handleUpdateLeverage = async (tradeIndex, newLeverage) => {
    try {
      const currentTime = new Date((Math.floor(new Date().getTime() / 1000) + diff) * 1000);
      const testnet = Testnet(1);
      
      const message = {
        update_leverage: {
          trade_index: tradeIndex,
          new_leverage: newLeverage.toString()
        }
      };

      const res = await executePerps(
        null, null, currentTime, testnet.chainId,
        testnet.endptTm, process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch, null, null, message, account, "leap"
      );

      if(res) {
        await getMarkets();
        toast.success("Leverage updated successfully");
      }
      return res;
    } catch (err) {
      console.error("Update leverage error:", err);
      toast.error(`Failed to update leverage: ${err.message}`);
      return false;
    }
  };

  const handleUpdateLimitOrder = async (tradeIndex, price, tp, sl) => {
    try {
      if (!tradeIndex || !price) {
        throw new Error("Trade index and price are required");
      }

      const currentTime = new Date((Math.floor(new Date().getTime() / 1000) + diff) * 1000);
      const testnet = Testnet(1);
      
      const message = {
        update_open_limit_order: {
          trade_index: tradeIndex,
          price: price.toString(),
          tp: tp ? tp.toString() : null,
          sl: sl ? sl.toString() : null,
          slippage_p: "0.01" // 1% default slippage
        }
      };

      const res = await executePerps(
        null, null, currentTime, testnet.chainId,
        testnet.endptTm, process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch, null, null, message, account, "leap"
      );

      if(res) {
        await getMarkets();
        toast.success("Limit order updated successfully");
        return true;
      }
      return false;
    } catch (err) {
      console.error("Update limit order error:", err);
      toast.error(`Failed to update limit order: ${err.message}`);
      return false;
    }
  };

  const handleCancelLimitOrder = async (tradeIndex) => {
    try {
      const currentTime = new Date((Math.floor(new Date().getTime() / 1000) + diff) * 1000);
      const testnet = Testnet(1);
      
      const message = {
        cancel_open_limit_order: {
          trade_index: tradeIndex
        }
      };

      const res = await executePerps(
        null, null, currentTime, testnet.chainId,
        testnet.endptTm, process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch, null, null, message, account, "leap"
      );

      if(res) {
        await getMarkets();
        toast.success("Limit order cancelled successfully");
      }
      return res;
    } catch (err) {
      console.error("Cancel limit order error:", err);
      toast.error(`Failed to cancel limit order: ${err.message}`);
      return false;
    }
  };

  const handleIncreasePositionSize = async (tradeIndex, leverageDelta, openPrice) => {
    try {
      const currentTime = new Date((Math.floor(new Date().getTime() / 1000) + diff) * 1000);
      const testnet = Testnet(1);

      const collateralAmount = Math.floor(Number(leverageDelta) * 1000000).toString();
      
      const tokenToSend = [{
        amount: collateralAmount,
        denom: REQUIRED_PARAMS.DENOM 
      }];

      const message = {
        increase_position_size: {
          trade_index: tradeIndex,
          leverage_delta: leverage_value.toString(),
          open_price: "69000",
          slippage_p: "0.01"
        }
      };

      console.log("Sending message:", message); // Debug log

      const res = await executePerps(
        null,
        null, 
        currentTime,
        testnet.chainId,
        testnet.endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        null,
        null,
        message,
        account,
        "leap",
        tokenToSend
      );

      if(res) {
        await getMarkets();
        toast.success("Position size increased successfully");
      }
      return res;

    } catch (err) {
      console.error("Increase position error:", err);
      toast.error(`Failed to increase position size: ${err.message}`);
      return false;
    }
  };

  const handleDecreasePositionSize = async (tradeIndex, collateralDelta, leverageDelta) => {
    try {
      const currentTime = new Date((Math.floor(new Date().getTime() / 1000) + diff) * 1000);
      const testnet = Testnet(1);

      const message = {
        decrease_position_size: {
          trade_index: tradeIndex,
          collateral_delta: collateralDelta,
          leverage_delta: leverageDelta
        }
      };

      console.log("Decrease position payload:", message);

      const res = await executePerps(
        null, null, currentTime, testnet.chainId,
        testnet.endptTm, process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch, null, null, message, account, "leap"
      );

      if(res) {
        await getMarkets();
        toast.success("Position decreased successfully");
      }
      return res;

    } catch (err) {
      console.error("Decrease position error:", err);
      toast.error(`Failed to decrease position: ${err.message}`);
      return false;
    }
  };

  const handleTriggerTrade = async (trader, tradeIndex, orderType) => {
    try {
      const currentTime = new Date((Math.floor(new Date().getTime() / 1000) + diff) * 1000);
      const testnet = Testnet(1);

      if (!Object.values(PendingOrderType).includes(orderType)) {
        throw new Error(`Invalid order type. Must be either 'limit_open' or 'stop_open'`);
      }

      const message = {
        trigger_trade: {
          trader: trader,
          trade_index: tradeIndex,
          order_type: orderType
        }
      };

      console.log("Trigger trade payload:", message);

      const res = await executePerps(
        null, null, currentTime, testnet.chainId,
        testnet.endptTm, process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch, null, null, message, account, "leap"
      );

      if(res) {
        await getMarkets();
        toast.success("Trade triggered successfully");
      }
      return res;
    } catch (err) {
      console.error("Trigger trade error:", err);
      toast.error(`Failed to trigger trade: ${err.message}`);
      return false;
    }
  };  

  useEffect(() => {
    if (account) {
      getMarkets();
      getOis();
    }
  }, [account]);

  const chartRef = useRef(null);

  const [chartRenderCount, setChartRenderCount] = useState(0);

  useEffect(() => {
    if (chartRef.current) {
      setChartRenderCount(prev => {
        if (prev >= 1) {
          return prev;
        }
        return prev + 1;
      });
    }
  }, []);


  



  return (
    <div className="w-full h-[calc(100vh-100px)] overflow-auto">
      <div className="flex gap-[20px] w-[80vw] mx-auto my-[20px]">
        <div className="w-[calc(80vw-300px)] h-max">
          <div
            onClick={() => navigate(-1)}
            className="w-full cursor-pointer flex gap-2 rounded-[8px] px-2 py-3 hover:bg-[#f6f6f6] bg-white shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
          >
            <ArrowToLeft />
            <div>Back to Market Page</div>
          </div>

         <Bitcoin/>

          <div className="mt-4 bg-white w-full p-[10px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
            <div className="w-full flex justify-end">
            </div>
            <TradingViewWidget />
          </div>

          <div className="mt-4 bg-white w-full p-[10px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
            <div className="w-full flex justify-between items-center">
              <SelectionGroup
                className="px-[6px] w-max flex py-[4px] gap-[8px] items-center rounded-[8px] bg-[#f6f6f6] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
                defaultItem={list}
              >
                <SelectionItem
                  SelectedItem={
                    <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                      <div className="text-black font-semibold">Trades</div>
                      <LightIcon />
                    </div>
                  }
                  UnselectedItem={
                    <div
                      onClick={() => setList(0)}
                      className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center"
                    >
                      <div className="text-[#959595]">Trades</div>
                    </div>
                  }
                />
                <SelectionItem
                  SelectedItem={
                    <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                      <div className="text-black font-semibold">Orders</div>
                      <LightIcon />
                    </div>
                  }
                  UnselectedItem={
                    <div
                      onClick={() => setList(1)}
                      className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center"
                    >
                      <div className="text-[#959595]">Orders</div>
                    </div>
                  }
                />
                <SelectionItem
                  SelectedItem={
                    <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                      <div className="text-black font-semibold">History</div>
                      <LightIcon />
                    </div>
                  }
                  UnselectedItem={
                    <div
                      onClick={() => setList(2)}
                      className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center"
                    >
                      <div className="text-[#959595]">History</div>
                    </div>
                  }
                />
              </SelectionGroup>
              {list === 0 && (
                <div className="flex gap-[10px]">
                  <div>
                    <div className="text-[12px] text-[#a6a6a6]">Collateral</div>
                    <div className="text-[12px]">$318</div>
                  </div>
                  <div>
                    <div className="text-[12px] text-[#a6a6a6]">
                      Unrealized PnL
                    </div>
                    <div className="text-[12px] text-[#5DBE89]">
                      $10.44 (+1.45)
                    </div>
                  </div>
                </div>
              )}
            </div>
            <hr className="mt-3" />
            {list === 0 && (
              <table>
                <tr className="w-full h-[40px]">
                  <td className="w-[12.5%]">Pair</td>
                  <td className="w-[12.5%]">Size</td>
                  <td className="w-[12.5%]">Collateral</td>
                  <td className="w-[12.5%]">Open Price</td>
                  <td className="w-[20%]">Current/Liq.Price</td>
                  <td className="w-[12.5%]">SL/TP</td>
                  <td className="w-[12.5%]">uPnL</td>
                  <td className="w-[5%]">Actions</td>
                </tr>

                {trades?.map((trade) => {
                  if (trade.is_open)
                    return (
                      <tr
                        // onClick={() => {
                        //   setModalFlag(1);
                        //   setShowModal(true);
                        // }}
                        className="hover:bg-[#f0f0f0] cursor-pointer w-full font-normal border-t-[1px]"
                      >
                        <td>BTC/USD</td>
                        <td>
                          <div>
                            <div className="text-[12px] text-[#a6a6a6]">
                              {Number(trade.collateral_amount) / 1000000} USDC
                            </div>
                            <div className="text-[12px]">
                              {(
                                Number(trade.collateral_amount) /
                                1000000 /
                                trade.open_price
                              ).toFixed(7)}{" "}
                              BTC
                            </div>
                          </div>
                        </td>
                        <td>
                          {Number(trade.collateral_amount) / 1000000} USDC
                        </td>
                        <td>{trade.open_price}</td>
                        <td>
                          <div>
                            <div className="text-[12px] text-[#a6a6a6]">
                              {open_price}
                            </div>
                            <div className="text-[12px]">326</div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div className="text-[12px] text-[#a6a6a6]">
                              {trade.sl}
                            </div>
                            <div className="text-[12px]">{trade.tp}</div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div className="text-[12px] text-[#EB4245]">
                              -50 USDC
                            </div>
                            <div className="text-[12px] text-[#EB4245]">
                              (-10.5%)
                            </div>
                          </div>
                        </td>
                        <td>
                        <div className="flex  gap-2">
                        <button
                          onClick={() => {
                            setSelectedTradeIndex(trade.user_trade_index);
                            setUpdateModalOpen(true);
                          }}
                          className="px-3 py-1 text-[#5B1DEE] underline text-white rounded-md hover:bg-opacity-80 mb-2"
                        >
                          Update
                        </button>
                        <div
                          onClick={() => {
                            handleCloseTrade(trade.user_trade_index);
                          }}
                          className="cursor-pointer text-center px-3 py-1 text-[#DB1F22] underline rounded-md hover:bg-opacity-80"
                        >
                          Close
                        </div>
                      </div>
                        </td>
                      </tr>
                    );
                })}
              </table>
            )}
            {list === 1 && (
              <table>
                <tr className="w-full h-[40px]">
                  <td className="w-[10%]">Type</td>
                  <td className="w-[10%]">Pair</td>
                  <td className="w-[10%]">Size</td>
                  <td className="w-[10%]">Leverage</td>
                  <td className="w-[10%]">Collateral</td>
                  <td className="w-[10%]">Trigger Price</td>
                  <td className="w-[10%]">Execution Price</td>
                  <td className="w-[10%]">Max Spread</td>
                  <td className="w-[10%]">SL/TP</td>
                  <td className="w-[10%]">Close</td>
                </tr>
                <tr
                  onClick={() => {
                    setModalFlag(2);
                    setShowModal(true);
                  }}
                  className="hover:bg-[#f0f0f0] cursor-pointer w-full font-normal border-t-[1px]"
                >
                  <td className="text-[#EB4245]">LIMIT</td>
                  <td>ESTATE/USDC</td>
                  <td>
                    <div>
                      <div className="text-[12px] text-[#a6a6a6]">300 USDC</div>
                      <div className="text-[12px]">0.45 ESTATE</div>
                    </div>
                  </td>
                  <td>2x</td>
                  <td>100 USDC</td>
                  <td>344</td>
                  <td>650</td>
                  <td>1%</td>
                  <td>
                    <div>
                      <div className="text-[12px] text-[#a6a6a6]">SL: None</div>
                      <div className="text-[12px]">TP: None</div>
                    </div>
                  </td>
                  <td>
                    <CloseIcon />
                  </td>
                </tr>
              </table>
            )}

            {list === 2 && (
              <table className="w-full">
                <tr className="w-full h-[40px]">
                  <td className="w-[10%]">Date</td>
                  <td className="w-[10%]">Pair</td>
                  <td className="w-[10%]">Type</td>
                  <td className="w-[10%]">Price</td>
                  <td className="w-[10%]">Lev</td>
                  <td className="w-[10%]">Coll</td>
                  <td className="w-[10%]">Size</td>
                  <td className="w-[10%]">PnL</td>
                  <td className="w-[10%]">%</td>
                </tr>
                <tr
                  onClick={() => {
                    setModalFlag(3);
                    setShowModal(true);
                  }}
                  className="hover:bg-[#f0f0f0] cursor-pointer w-full font-normal border-t-[1px]"
                >
                  <td>
                    <div className="flex items-center gap-[10px]">
                      <div>07/19</div>
                      <div className="text-[#a6a6a6] text-[12px]">07/19</div>
                    </div>
                  </td>
                  <td>ESTATE/USDC</td>
                  <td className="text-[#EB4245]">MARKET</td>
                  <td>657</td>
                  <td>2</td>
                  <td>200</td>
                  <td>400 USDC</td>
                  <td className="text-[#EB4245]">-100 USDC</td>
                  <td className="text-[#EB4245]">-5.8%</td>
                </tr>
              </table>
            )}
            
          </div>       
        </div>
        <div className="space-y-[10px] bg-white w-[380px] p-[10px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
          <SelectionGroup
            className="px-[6px] w-full grid grid-cols-3 py-[4px] gap-[8px] items-center rounded-[8px] bg-[#f6f6f6] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
            defaultItem={longShort}
          >
            <SelectionItem
              SelectedItem={
                <div className="cursor-pointer items-center bg-white rounded-[8px] w-full flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                  <LongIcon/>

                  <div className="text-black font-semibold">Long</div>
                  <LightIcon />
                </div>
              }
              UnselectedItem={
                <div
                  onClick={() => setLongShort(0)}
                  className="cursor-pointer items-center gap-2 hover:bg-white rounded-[8px] w-full flex justify-center"
                >
                  <LightLongIcon/>
                  <div className="text-[#959595]">Long</div>
                </div>
              }
            />
            <SelectionItem
              SelectedItem={
                <div className="cursor-pointer items-center bg-white rounded-[8px] w-full flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
               <DownIcon/>
                  <div className="text-black font-semibold">Short</div>
                  <LightIcon />
                </div>
              }
              UnselectedItem={
                <div
                  onClick={() => setLongShort(1)}
                  className="cursor-pointer items-center gap-2 hover:bg-white rounded-[8px] w-full flex justify-center"
                >
                <ShortDownIcon/>
                  <div className="text-[#959595]">Short</div>
                </div>
              }
            />
            <SelectionItem
              SelectedItem={
                <div className="cursor-pointer items-center bg-white rounded-[8px] w-full flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                <Swap/>

                  <div className="text-black font-semibold">Swap</div>
                  <LightIcon />
                </div>
              }
              UnselectedItem={
                <div
                  onClick={() => setLongShort(2)}
                  className="cursor-pointer items-center gap-2 hover:bg-white rounded-[8px] w-full flex justify-center"
                >
             <Swap2/>

                  <div className="text-[#959595]">Swap</div>
                </div>
              }
            />
          </SelectionGroup>
          <div className="flex items-center justify-between">
            <SelectionGroup
              className="px-[12px] flex py-[4px] gap-[20px] items-center"
              defaultItem={tab}
            >
              <SelectionItem
                SelectedItem={
                  <div
                  // className="cursor-pointer bg-white rounded-[0px] w-full flex justify-center gap-[10px]"
                  >
                    <div className="text-black font-semibold">Market</div>
                  </div>
                }
                UnselectedItem={
                  <div
                    onClick={() => setTab(0)}
                    // className="cursor-pointer hover:bg-white rounded-[0px] w-full flex justify-center"
                  >
                    <div className="text-[#959595]">Market</div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div
                  // className="cursor-pointer bg-white rounded-[0px] w-full flex justify-center gap-[10px]"
                  >
                    <div className="text-black font-semibold">Limit</div>
                  </div>
                }
                UnselectedItem={
                  <div
                    onClick={() => setTab(1)}
                    // className="cursor-pointer hover:bg-white rounded-[0px] w-full flex justify-center"
                  >
                    <div className="text-[#959595]">Limit</div>
                  </div>
                }
              />
            </SelectionGroup>
            <div>
             <Settings/>
            </div>
          </div>

          {(longShort === 0 || longShort === 1) && (
            <>
              <div className="w-full flex flex-col items-center">
                <div className="w-full p-[12px] border-[2px] rounded-[8px] bg-[#F6F6F6]">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div className="p-1 rounded-full bg-[#5b1dee]">
                      <MarketIcon/>
                      </div>
                      <div className="text-[18px]">Market </div>
                      <div className="text-[#5A5A5A] font-normal text-[10px]">
                        Pay : $2756.43
                      </div>
                    </div>

                    <div className="py-[2px] px-[6px] bg-white border-[2px] rounded-[8px]">
                      <div className="text-[14px]">Deposit Collateral</div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <input
                      className="text-[20px] outline-none w-[200px]"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <div className="flex gap-1">
                      <NUSDIcon />
                      <div>USDC</div>
                      <ArrowToDownHeader />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full p-[16px] border-[1px] rounded-[4px]">
                <div>Leverage</div>

                <div className="mb-7 mt-2 px-2">
                  <Slider
                    min={logScale(labels[0])}
                    max={logScale(labels[labels.length - 1])}
                    value={logScale(leverage_value)}
                    onChange={handleChange}
                    marks={labels.reduce((acc, label) => {
                      acc[logScale(label)] = label.toString() + "x"; // Set mark labels
                      return acc;
                    }, {})}
                    step={0.1}
                    dotStyle={{
                      width: "2px",
                      height: "14px",
                      translate: "0px 4px",
                      background: "#E3E3E3",
                      borderColor: "#E3E3E3",
                    }}
                    styles={{
                      handle: {
                        width: "24px",
                        height: "24px",
                        borderRadius: "8px",
                        borderColor: "#D5D5D5",
                        background: "#E3E3E3",
                        boxShadow: "none",
                        translate: "0px -4px",
                      },
                      rail: {
                        background: "#F6F6F6",
                      },
                      track: {
                        background: "#e3e3e3",
                      },
                    }}
                  />
                </div>
                <div className="w-full flex gap-4 items-center">
                  <div className="flex gap-2 bg-white px-[12px] w-full py-[8px] border-[2px] border-[#E3E3E3] rounded-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                    <div>x</div>
                    <input
                      className="outline-none w-full text-[#5a5a5a] font-normal"
                      value={leverage_value}
                      placeholder="Profit"
                      onChange={(e) => setLeverageValue(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-between">
                <div className="text-[14px]">Manual TP/SL</div>
                <Toggle 
                  status={isManualTPSL} 
                 onChange={() => setIsManualTPSL(!isManualTPSL)} 
                   />
              </div>
              {isManualTPSL ? (
                <>
                <div>
            <div className="font-semibold">Take Profit</div>
            <div className="w-full grid grid-cols-2 mt-2 gap-2">
              <div className="flex gap-2 bg-white px-[12px] w-full py-[8px] border-[2px] border-[#E3E3E3] rounded-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,257,0.8)]">
                <input
                  className="outline-none w-full text-[#5a5a5a] font-normal"
                  placeholder="Price"
                  value={tp}
                  onChange={(e) => setTp(e.target.value)}
                />
              </div>
              <div className="flex gap-2 bg-white px-[12px] w-full py-[8px] border-[2px] border-[#E3E3E3] rounded-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,257,0.8)]">
                <div className="text-[#38A569]">+$</div>
                <input
                  className="outline-none w-full text-[#5a5a5a] font-normal"
                  placeholder="Profit"
                  value={calculateProfit(position).toFixed(2)}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div>
            <div className="font-semibold">Stop Loss</div>
            <div className="w-full grid grid-cols-2 mt-2 gap-2">
              <div className="flex gap-2 bg-white px-[12px] w-full py-[8px] border-[2px] border-[#E3E3E3] rounded-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,257,0.8)]">
                <input
                  className="outline-none w-full text-[#5a5a5a] font-normal"
                  placeholder="Price"
                  value={sl}
                  onChange={(e) => setSl(e.target.value)}
                />
              </div>
              <div className="flex gap-2 bg-white px-[12px] w-full py-[8px] border-[2px] border-[#E3E3E3] rounded-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,257,0.8)]">
                <div className="text-[#DB1F22]">-$</div>
                <input
                  className="outline-none w-full text-[#5a5a5a] font-normal"
                  placeholder="Loss"
                />
              </div>
            </div>
          </div>
                </>
              ):(
                <>
                 <div className="opacity-50 pointer-events-none">
         
          <div>
            <div className="font-semibold text-gray-400">Take Profit</div>
            <div className="w-full grid grid-cols-2 mt-2 gap-2">
              <div className="flex gap-2 bg-gray-200 px-[12px] w-full py-[8px] border-[2px] border-[#E3E3E3] rounded-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,257,0.8)]">
                <input
                  className="outline-none w-full text-[#5a5a5a] font-normal"
                  placeholder="Price"
                  disabled
                />
              </div>
              <div className="flex gap-2 bg-gray-200 px-[12px] w-full py-[8px] border-[2px] border-[#E3E3E3] rounded-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,257,0.8)]">
                <div className="text-gray-400">+$</div>
                <input
                  className="outline-none w-full text-[#5a5a5a] font-normal"
                  placeholder="Profit"
                  disabled
                />
              </div>
            </div>
          </div>
          <div>
            <div className="font-semibold text-gray-400">Stop Loss</div>
            <div className="w-full grid grid-cols-2 mt-2 gap-2">
              <div className="flex gap-2 bg-gray-200 px-[12px] w-full py-[8px] border-[2px] border-[#E3E3E3] rounded-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,257,0.8)]">
                <input
                  className="outline-none w-full text-[#5a5a5a] font-normal"
                  placeholder="Price"
                  disabled
                />
              </div>
              <div className="flex gap-2 bg-gray-200 px-[12px] w-full py-[8px] border-[2px] border-[#E3E3E3] rounded-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,257,0.8)]">
                <div className="text-gray-400">-$</div>
                <input
                  className="outline-none w-full text-[#5a5a5a] font-normal"
                  placeholder="Loss"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
                </>
              )}
             
              <GreenButton
                text="Market Long"
                onClick={() => handleOpenTrade()}
              />
              <div className="w-full text-[12px] space-y-[6px]">
                <div className="w-full justify-between flex">
                  <span>ESTATE/USD</span>
                </div>
                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Est. Execution Price</span>
                  <span>678.48</span>
                </div>
                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Avg.Spread</span>
                  <span>0.00%</span>
                </div>
                <div className="w-full justify-between flex items-center">
                  <span className="text-[#959595]">Max Slippage</span>
                  <div className="flex items-end bg-white px-[8px] w-[60px] py-[2px] border-[0.5px]">
                    <input
                      className="outline-none w-full"
                      value={leverage}
                      onChange={(e) => setLeverage(e.target.value)}
                    />
                    <span className="text-[10px]">%</span>
                  </div>
                </div>
                <hr />

                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Notional Value</span>
                  <span>233 USDC</span>
                </div>
                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Position Size</span>
                  <span>0.34 ESTATE</span>
                </div>
                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Fees</span>
                  <span>3.0 USDC</span>
                </div>
                <hr />

                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Liq* Price</span>
                  <span>628</span>
                </div>
                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Est. Borrowing Fee / H</span>
                  <span>0 USDC</span>
                </div>
              </div>
            </>
          )}
          {longShort === 2 && (
            <>
              <div>Review Swap</div>
              <div className="w-full flex flex-col items-center">
                <div className="w-full p-[12px] border-[2px] rounded-[8px] bg-[#F6F6F6]">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div className="p-1 rounded-full bg-[#5b1dee]">
                       <SellIcon/>
                      </div>
                      <div className="text-[18px]">Sell</div>
                      <div className="text-[#5A5A5A] font-normal text-[10px]">
                        $10.02
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-[20px]">500</div>
                    <div className="flex gap-1 items-center">
                      <NUSDIcon />

                      <div>USDC</div>
                      <ArrowToDownHeader />
                    </div>
                  </div>
                </div>
                <div className="border-[2px] p-2 w-max rounded-full bg-white mt-[-16px] z-10">
                <SellBuyMiddleIcon/>
                </div>
                <div className="w-full p-[12px] border-[2px] rounded-[8px] bg-[#F6F6F6] mt-[-16px]">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div className="p-1 rounded-full bg-[#5b1dee]">
                        <BuyIcon/>
                      </div>
                      <div className="text-[18px]">Buy</div>
                      <div className="text-[#5A5A5A] font-normal text-[10px]">
                        $9.96
                      </div>
                      <div className="text-[#A8A8A8] font-normal text-[10px]">
                        (-0.5%)
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-[20px]">500</div>
                    <div className="flex gap-1 items-center">
                      <NibiruIcon />

                      <div>Nibiru</div>
                      <ArrowToDownHeader />
                    </div>
                  </div>
                </div>
              </div>
              <GreenButton text="Swap" />
              <div className="w-full text-[12px] space-y-[6px]">
                <div className="w-full justify-between flex">
                  <span>NIBI/USD</span>
                </div>
                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Rate</span>
                  <span>1 DUBAI = 0.51279 USDC ($0.512)</span>
                </div>
                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Fee (0.25%)</span>
                  <span>$0.02</span>
                </div>
                <div className="w-full justify-between flex items-center">
                  <span className="text-[#959595]">Network cost</span>
                  <div className="flex items-center gap-2">
                   
                      <GasIcon/>
                    <span>$0.02</span>
                  </div>
                </div>

                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Price impact</span>
                  <span>~0.56%</span>
                </div>
                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Max. slippage</span>
                  <div className="flex gap-1 items-center">
                    <div className="border-[1px] bg-[#F6F6F6] border-[#E3E3E3] rounded-md px-1">
                      <span className="text-[#5A5A5A]">Auto</span>
                    </div>
                    <span>0.5%</span>
                  </div>
                </div>
                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Receive at least</span>
                  <span>19.501 DUBAI</span>
                </div>
                <hr />
                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Fee (0.25%)</span>
                  <span>$0.02</span>
                </div>
                <div className="w-full justify-between flex">
                  <span className="text-[#959595]">Order routing</span>
                  <span>Uniswap API</span>
                </div>
              </div>

              <PurpleButton text="Finish" />
              <BlackButton text="Explorer Page" />
              <div className="w-full">
                <div className="text-[14px]">Message</div>
                <div className="w-full flex gap-2 items-center mt-2">
                  <div className="flex flex-col items-center">
                    <div className="relative">
                      <div className="bg-[#5b1dee] rounded-full p-2">
                      <ApprovalIcon/>
                      </div>
                      <div className="absolute top-5 right-0 bg-[#38A569] p-[2px] w-max rounded-full">
                        <Approal2/>
                      </div>
                    </div>
                    <div className="h-[24px] bg-[#E3E3E3] w-[2px]" />

                    <div className="relative">
                      <div className="bg-[#F6F6F6] rounded-full p-2">
                        <Approval3/>
                      </div>
                      <div className="absolute top-5 right-0 bg-[#F8BC30] p-[2px] w-max rounded-full">
                       <Approval4/>
                      </div>
                    </div>
                    <div className="h-[24px] bg-[#E3E3E3] w-[2px]" />

                    <div>
                      <div className="bg-[#F6F6F6] rounded-full p-2">
                        <Approal5/>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-rows-3 h-[170px]">
                    <div className="flex flex-col justify-center">
                      <div>Approval pending..</div>
                      <div className="text-[#5b1dee] font-normal text-[12px]">
                        Why do I have to approve a token?
                      </div>
                    </div>
                    <div className="flex flex-col justify-center">
                      <div>Approval pending..</div>
                      <div className="text-[#5b1dee] font-normal text-[12px]">
                        Why are signature required?
                      </div>
                    </div>
                    <div className="flex flex-col justify-center">
                      <div>Approval pending..</div>
                      <div className="text-[#5b1dee] font-normal text-[12px]">
                        Learn more about swap?
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        open={showModal}
        center
        onClose={() => setShowModal()}
        classNames={{
          modal:
            "min-w-[350px] space-y-[10px] h-max rounded-[8px] border-[1px] border-[#E3E3E3] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]",
          overlay: "z-50",  // Add high z-index to overlay
          modalContainer: "z-50"  // Add high z-index to modal container
        }}
      >
        {modalFlag === 0 && (
          <>
            <div className="w-full text-center font-medium mt-4 text-[20px] flex items-center justify-center gap-[10px]">
              <NUSDIcon />
              <div>ESTATE/USDC</div>
              <div className="text-[12px] p-[4px] rounded-[4px] border-[0.5px] border-[#5DBE89] text-[#5DBE89]">
                LONG
              </div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Collateral</div>
              <div>200 USDC</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Leverage</div>
              <div>2x</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Position Size</div>
              <div>
                <div>200 USDC</div>
                <div className="text-[#a6a6a6]">0.45 ESTATE</div>
              </div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Est. Open Price</div>
              <div>645</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Max Slippage</div>
              <div>1%</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Liq. Price</div>
              <div>357</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Stop Loss</div>
              <div>None</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Take Profit</div>
              <div>700</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Fees</div>
              <div>10 USDC (Min Fee)</div>
            </div>

            <PurpleButton text="Confirm" onClick={() => setShowModal(false)} />
          </>
        )}
        {modalFlag === 1 && (
          <>
            <div className="w-full text-center font-medium mt-4 text-[20px] flex items-center justify-center gap-[10px]">
              <NUSDIcon />
              <div>ESTATE/USDC</div>
              <div className="text-[12px] p-[4px] rounded-[4px] border-[0.5px] border-[#5DBE89] text-[#5DBE89]">
                LONG
              </div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Collateral</div>
              <div>200 USDC</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Leverage</div>
              <div>2x</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Position Size</div>
              <div>
                <div>200 USDC</div>
                <div className="text-[#a6a6a6]">0.45 ESTATE</div>
              </div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Open Price</div>
              <div>645</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Current Price</div>
              <div>653</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Price Change</div>
              <div>2.23%</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Borrowing Fees</div>
              <div>0 USDC</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Closing Fees</div>
              <div>20 USDC</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Unrealized PnL</div>
              <div>10 USDC</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Receive</div>
              <div>10 USDC</div>
            </div>
            <PurpleButton
              text="Close Trade"
              onClick={() => setShowModal(false)}
            />
          </>
        )}
        {modalFlag === 2 && (
          <>
            <div className="w-full text-center font-medium mt-4 text-[20px] flex items-center justify-center gap-[10px]">
              <NUSDIcon />
              <div>ESTATE/USDC</div>
              <div className="text-[12px] p-[4px] rounded-[4px] border-[0.5px] border-[#EB4245] text-[#EB4245]">
                LIMIT SHORT
              </div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Leverage</div>
              <div>2x</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Collateral</div>
              <div>200 USDC</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Position Size</div>
              <div>
                <div>200 USDC</div>
                <div className="text-[#a6a6a6]">0.45 ESTATE</div>
              </div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Trigger Price</div>
              <div>645</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Execution Price</div>
              <div>653</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Max Spread (%)</div>
              <div>2.23</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Stop Loss</div>
              <div>None</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Take Profit</div>
              <div>None</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Opening Fee</div>
              <div>10 USDC</div>
            </div>

            <PurpleButton
              text="Close Order"
              onClick={() => setShowModal(false)}
            />
          </>
        )}
        {modalFlag === 3 && (
          <>
            <div className="mt-8 w-full flex justify-between font-normal">
              <div className="flex items-center gap-[10px]">
                <NUSDIcon />
                <div>ESTATE/USDC</div>
              </div>
              <div className="flex items-center gap-[4px]">
                <span className="text-[12px]">CLOSE @ 234</span>
              </div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Date</div>
              <div>07/19</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Position Size</div>
              <div className="text-[#EB4245]">45 ESTATE</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Leverage</div>
              <div>2x</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>Collateral</div>
              <div>300 USDC</div>
            </div>
            <div className="w-full flex justify-between font-normal">
              <div>PnL</div>
              <div className="text-[#EB4245]">-50 USDC (-5.8%)</div>
            </div>
          </>
        )}
      </Modal>

      <Modal
        open={false}
        center
        // onClose={() => setShowModal()}
        classNames={{
          modal:
            "min-w-[400px] h-max rounded-[8px] border-[1px] border-[#E3E3E3] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]",
        }}
      >
        <div>
          <div className="text-[20px] font-semibold">Trade settings</div>
          <div className="w-full items-center flex justify-between my-4">
            <div className="flex items-center gap-2">
              <div>Slip Tolerance</div>
             <Slip/>
            </div>
            <div className="text-[#5b1dee] text-[12px]">
              What are trade settings?
            </div>
          </div>

          <div className="w-full items-center justify-between gap-2 flex mb-4">
            <SelectionGroup
              className="px-[6px] w-max flex py-[4px] gap-[8px] items-center rounded-[8px] bg-[#f6f6f6]"
              defaultItem={list}
            >
              <SelectionItem
                SelectedItem={
                  <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                    <div className="text-black font-semibold">0.5%</div>
                    <LightIcon />
                  </div>
                }
                UnselectedItem={
                  <div
                    onClick={() => setList(0)}
                    className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center"
                  >
                    <div className="text-[#959595]">0.5%</div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                    <div className="text-black font-semibold">5%</div>
                    <LightIcon />
                  </div>
                }
                UnselectedItem={
                  <div
                    onClick={() => setList(1)}
                    className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center"
                  >
                    <div className="text-[#959595]">5%</div>
                  </div>
                }
              />
              <SelectionItem
                SelectedItem={
                  <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                    <div className="text-black font-semibold">10%</div>
                    <LightIcon />
                  </div>
                }
                UnselectedItem={
                  <div
                    onClick={() => setList(2)}
                    className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center"
                  >
                    <div className="text-[#959595]">10%</div>
                  </div>
                }
              />
            </SelectionGroup>
            <div className="flex gap-2 bg-white px-[12px] w-[80px] py-[2px] border-[2px] border-[#E3E3E3] rounded-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
              <input
                className="outline-none w-full text-[#5a5a5a] font-normal"
                placeholder=""
              />
              <div className="text-[#D5D5D5]">%</div>
            </div>
          </div>
          <PurpleButton text="Save settings" />
        </div>
      </Modal>


    <Modal
      open={updateModalOpen}
      center
      onClose={() => setUpdateModalOpen(false)}
      classNames={{
        modal: "min-w-[500px] h-max rounded-[8px] border-[1px] border-[#E3E3E3]"
      }}
    >
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-4 text-[#202020] font-bold">Manage Position</h2>

       
        <div className="flex gap-2 mb-4">
          {[
            {id: 'position', label: 'Position'},
            {id: 'size', label: 'Size'},
            {id: 'limit', label: 'Limit Order'},
            {id: 'actions', label: 'Actions'}
          ].map(tab => (
            <div 
      key={tab.id}
      onClick={() => setActiveTab(tab.id)}
      className={`py-[4px] cursor-pointer rounded-[10px] w-[120px] flex justify-center ${
        activeTab === tab.id 
        ? 'bg-white hover:bg-[#f6f6f6] rounded-[10px] gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]'
        : 'hover:bg-[#f6f6f6]'
      }`}
    >
      {activeTab === tab.id ? (
        <div className="flex items-center gap-[10px]">
          <div className="text-black font-semibold">{tab.label}</div>
          <LightIcon />
        </div>
      ) : (
        <div className="text-[#959595]">{tab.label}</div>
      )}
    </div>
          ))}
        </div>

       
        {activeTab === 'position' && (
          <div className="space-y-4">
              <div>
                <label className="block text-sm font-semibold mt-2 mb-2 text-[#202020] ">Take Profit</label>
                <div className="w-full flex gap-x-2 ">
                <input
                  type="number"
                  value={tp}
                  onChange={(e) => setTp(e.target.value)}
                  className="w-[75%] shadow-md px-3 py-2 border rounded-md outline-none"
                  placeholder="Enter new take profit"
                />
                <div className="w-[25%]">
                <GreenButton
                  onClick={() => {
                    if (selectedTradeIndex !== null) {
                      handleUpdateTp(selectedTradeIndex, tp);
                    } else {
                      toast.error("No trade selected");
                    }
                  }}
                  text={"Update TP"}

                >
                  
                </GreenButton>
                </div>
                </div>
              </div>


              <div>
                <label className="block text-sm font-medium mb-2 text-[#202020] font-semibold">Stop Loss</label>
                <div className="flex gap-x-2 ">
                <input
                  type="number"
                  value={sl}
                  onChange={(e) => setSl(e.target.value)}
                  className="w-[75%] shadow-md px-3 py-2 border rounded-md outline-none"
                  placeholder="Enter new stop loss"
                />
                <div className="w-[25%]">
                <RedButton
                  text={"Close TP  "}
                  onClick={() => {
                    if (selectedTradeIndex !== null) {
                      handleUpdateSl(selectedTradeIndex, sl);
                    } else {
                      toast.error("No trade selected");
                    }
                  }}
                  
                />
                  </div>
                </div>
              </div>

              {/* Leverage Section */}
              <div>
                <label className="block text-sm font-medium mb-2">Leverage</label>
                <div className="flex gap-x-2 ">
                <input
                  type="number"
                  value={leverage_value}
                  onChange={(e) => setLeverageValue(e.target.value)}
                  className="w-[75%] shadow-md px-3 py-2 border rounded-md outline-none"
                  placeholder="Enter new leverage"
                />
                <div className="w-[25%]">
                <PurpleButton
                text={"Update"}
                  onClick={() => {
                    if (selectedTradeIndex !== null) {
                      handleUpdateLeverage(selectedTradeIndex, leverage_value);
                    } else {
                      toast.error("No trade selected");
                    }
                  }}
                >

                </PurpleButton>
                </div>
                </div>
              </div>
          </div>
        )}

        {activeTab === 'size' && (
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold mb-2 text-[#202020]">Increase Position Size</h3>
              <input
                type="number"
                value={modalInputs.leverageDelta}
                onChange={(e) => setModalInputs({...modalInputs, leverageDelta: e.target.value})}
                placeholder="Leverage Delta"
                className="w-full shadow-md px-3 py-2 border rounded-md"
              />
              <input
                type="number"
                value={modalInputs.openPrice}
                onChange={(e) => setModalInputs({...modalInputs, openPrice: e.target.value})}
                placeholder="Open Price"
                className="w-full shadow-md mt-2 px-3 py-2 border rounded-md"
              />
              <div className="mt-2">
              <GreenButton
              text={"Increase Position"}
                onClick={() => handleIncreasePositionSize(selectedTradeIndex, modalInputs.leverageDelta, modalInputs.openPrice)}
                className=" w-full px-4 py-2 bg-[#5DBE89] text-white rounded-md"
              />
                </div>
             
            </div>

            <div>
              <h3 className="font-semibold mb-2 text-[#202020]">Decrease Position Size</h3>
              <input
                type="number"
                value={modalInputs.collateralDelta}
                onChange={(e) => setModalInputs({...modalInputs, collateralDelta: e.target.value})}
                placeholder="Collateral Delta"
                className="w-full mt-2 px-3 py-2 border rounded-md"
              />
              <input
                type="number"
                step="0.1"
                value={modalInputs.leverageDelta}
                onChange={(e) => setModalInputs({...modalInputs, leverageDelta: e.target.value})}
                placeholder="Leverage Delta"
                className="w-full mt-2 px-3 py-2 border rounded-md"
              />
              <div className="mt-2">
              <RedButton
              text={"Decrease Position"}
                onClick={() => handleDecreasePositionSize(selectedTradeIndex, modalInputs.collateralDelta, modalInputs.leverageDelta)}
                className="mt-2 w-full px-4 py-2 bg-[#EB4245] text-white rounded-md"
              />
              </div>
              
            </div>
          </div>
        )}

        {activeTab === 'limit' && (
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold mb-2 text-[#202020]">Update Limit Order</h3>
              <input
                type="number"
                value={modalInputs.price}
                onChange={(e) => setModalInputs({...modalInputs, price: e.target.value})}
                placeholder="New Price"
                className="w-full shadow-md px-3 py-2 border rounded-md"
              />
              <div className="grid grid-cols-2 gap-2 mt-2">
                <input
                  type="number"
                  value={modalInputs.newTp}
                  onChange={(e) => setModalInputs({...modalInputs, newTp: e.target.value})}
                  placeholder="Take Profit"
                  className="px-3 shadow-md py-2 border rounded-md"
                />
                <input
                  type="number"
                  value={modalInputs.newSl}
                  onChange={(e) => setModalInputs({...modalInputs, newSl: e.target.value})}
                  placeholder="Stop Loss"
                  className="px-3 shadow-md py-2 border rounded-md"
                />
              </div>
              <div className="grid grid-cols-2 gap-2 mt-2">
                <PurpleButton
                text={"Update Limit"}
                  onClick={() => handleUpdateLimitOrder(selectedTradeIndex, modalInputs.price, modalInputs.newTp, modalInputs.newSl)}
                  className="px-4 py-2 bg-[#5b1dee] text-white rounded-md"
                />
                
                <button
                  onClick={() => handleCancelLimitOrder(selectedTradeIndex)}
                  className="px-4 py-2 bg-[#EB4245] text-white rounded-md"
                >
                  Cancel Limit
                </button>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'actions' && (
  <div className="space-y-4">
  <div>
    <label className="block text-sm font-medium mb-2">Actions Manage Position</label>
    <div className="grid grid-cols-2 gap-2">
      <button
        onClick={() => setModalInputs({
          ...modalInputs, 
          orderType: PendingOrderType.LIMIT_OPEN
        })}
        className={`px-4 py-2 rounded-md ${
          modalInputs.orderType === PendingOrderType.LIMIT_OPEN 
            ? 'bg-[#5b1dee] text-white' 
            : 'bg-gray-100'
        }`}
      >
        Limit Open
      </button>
      
      <button
        onClick={() => setModalInputs({
          ...modalInputs,
          orderType: PendingOrderType.STOP_OPEN
        })}
        className={`px-4 py-2 rounded-md ${
          modalInputs.orderType === PendingOrderType.STOP_OPEN
            ? 'bg-[#5b1dee] text-white'
            : 'bg-gray-100'
        }`}
      >
        Stop Open
      </button>
    </div>
  </div>

  <PurpleButton
  text={"Trigger Trade"}
    onClick={() => {
      if (!modalInputs.orderType) {
        toast.error("Please select an order type");
        return;
      }
      handleTriggerTrade(account, selectedTradeIndex, modalInputs.orderType)
    }}
  />
     
 
            <RedButton
            text={"Close Trade"}
              onClick={() => handleCloseTrade(selectedTradeIndex)}
              className="w-full px-4 py-2 bg-[#EB4245] text-white rounded-md"
            />
              
          </div>
        )}

      
      </div>
    </Modal>
    </div>
  );
};