import React from 'react'

function ApprovalIcon() {
  return (
    <div>  <svg
    className="h-[18px]"
    width="16"
    height="25"
    viewBox="0 0 16 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.30746 0.961816L8.75288 0.318362L8.30746 0L7.86203 0.318362L8.30746 0.961816ZM1.38911 5.90673L0.94368 5.26324L0.0581469 5.89622L0.933916 6.54304L1.38911 5.90673ZM15.2258 5.90673H16V5.49825L15.6712 5.26324L15.2258 5.90673ZM15.2258 16.1262L14.7706 16.7625L16 17.6705V16.1262H15.2258ZM14.6105 19.0932L15.0641 19.7307L15.9296 19.0949L15.0657 18.4568L14.6105 19.0932ZM0.774195 8.87378L1.22938 8.2374L0 7.32936V8.87378H0.774195ZM7.8793 24.0381L7.44189 24.6871L7.89139 25L8.33288 24.6756L7.8793 24.0381ZM0.774195 19.0932H0V19.5079L0.336711 19.7423L0.774195 19.0932ZM7.86203 0.318362L0.94368 5.26324L1.83453 6.55015L8.75288 1.60524L7.86203 0.318362ZM15.6712 5.26324L8.75288 0.318362L7.86203 1.60524L14.7804 6.55015L15.6712 5.26324ZM16 16.1262V5.90673H14.4516V16.1262H16ZM0.933916 6.54304L14.7706 16.7625L15.681 15.4898L1.8443 5.27035L0.933916 6.54304ZM15.0657 18.4568L1.22938 8.2374L0.319005 9.51009L14.1553 19.7295L15.0657 18.4568ZM8.33288 24.6756L15.0641 19.7307L14.1569 18.4557L7.42571 23.4006L8.33288 24.6756ZM0.336711 19.7423L7.44189 24.6871L8.31678 23.389L1.21168 18.4442L0.336711 19.7423ZM0 8.87378V19.0932H1.54839V8.87378H0Z"
      fill="white"
    />
  </svg></div>
  )
}

export default ApprovalIcon