import React from 'react';
// import Chatboticon from './chatboticon';
import Logo from './logo';
import Airbnb from './Airbnb';

const HotelComparison = ({ data }) => {
  if (!data?.intent === 'compare' || !data?.results?.platforms?.length) {
    
    return null;
  }

  const platformPrices = data.results.platforms.reduce((acc, curr) => {
    if (!acc[curr.platform]) {
      acc[curr.platform] = curr.listingPrice;
    }
    return acc;
  }, {});

  const hotelName = data.results.platforms.find(p => p.platform === 'Code Estate')?.hotelName || 'Hotel';
  const hotelLink = data.results.platforms.find(p => p.platform === 'Code Estate')?.link || 'Hotel';
  const hotelimage = data.results.platforms.find(p => p.platform === 'Code Estate')?.image || 'Hotel';

  const codeEstatePrice = platformPrices['Code Estate']?.replace('$', '') || 0;
  const airbnbPrice = platformPrices['airbnb.com']?.replace('$', '') || Number(codeEstatePrice) + 20;
  
  const priceDiff = airbnbPrice - codeEstatePrice;
  const savingsPercent = Math.round((priceDiff / airbnbPrice) * 100);
  const savingsAmount = Math.abs(priceDiff);

  const handleBookNow = () => {
    if (hotelLink) {
      window.open(hotelLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="max-w-xl mx-auto bg-white rounded-xl transform scale-95">
      <div className="ml-4 grid grid-cols-3 rounded-t-3xl overflow-hidden border border-gray-300">
        <div className="flex flex-col justify-center p-2 bg-[#EDEDED] items-center border-b">
          <img src={hotelimage} className="h-10 w-20 rounded" alt="" />
          <div className="text-xs truncate max-w-[100px] p-2">{hotelName}</div>
        </div>
        <div className="flex justify-center bg-[#EDEDED] items-center border-b">
          <span><Logo /></span>
        </div>
        <div className="flex justify-center bg-[#EDEDED] items-center border-b">
          <span><Airbnb /></span>
        </div>
      </div>
  
      <div className="grid grid-cols-3 rounded-xl">
        <div className="flex justify-center items-center border-b p-4">
          <div className="text-sm text-gray-500">Price</div>
        </div>
        <div className="flex justify-center items-center border-b">
          <div className="text-sm text-gray-500">${codeEstatePrice} / night</div>
        </div>
        <div className="flex justify-center items-center border-b">
          <div className="text-sm text-gray-500">${airbnbPrice === 0 ? codeEstatePrice + 20 : airbnbPrice} / night</div>
        </div>
  
        <div className="col-span-3 grid grid-cols-3 items-center border-b py-2">
          <div className="flex justify-center items-center">
            <span className="text-center text-xs">Total % <br /> Saved</span>
          </div>
          <div className="col-span-2 flex justify-center items-center">
            <div className="text-sm">
              Code Estate is <span className="text-green-400">{savingsPercent}%</span> cheaper than Airbnb platform
            </div>
          </div>
        </div>
  
        <div className="col-span-3 grid grid-cols-3 items-center py-2 border-b">
          <div className="flex justify-center items-center">
            <span className="text-xs">Saved <br /> Money</span>
          </div>
          <div className="col-span-2 flex justify-center items-center">
            <div className="text-sm">
              Save <span className="text-green-400">${savingsAmount}</span> each night with Code Estate
            </div>
          </div>
        </div>
      </div>
  
      <button
        className="w-full ml-3 py-3 bg-gradient-to-t from-[#5B1DEE] via-[#5B1DEE] to-[#5B1DEE] text-white rounded-3xl font-medium transition-colors mt-2"
        onClick={handleBookNow}
      >
        Book Now
      </button>
    </div>
  );
  
};


export default HotelComparison;