import React, { useState } from "react";
import Chatboticon from "./chatboticon";
import TypeWriter from "./TypeWriter";

const Unavailable = () => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const unavailableMessage = "Sorry, CodedEstate is currently only available in Dubai and Abu Dhabi. We're working hard to expand our services to more regions soon! Join our waitlist to be notified when we launch in your area.";

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!email || !email.includes('@') || !name) return;
    
    setIsSubmitting(true);
    try {
      const Api = process.env.REACT_APP_BACKEND_SERVER;
      await fetch(`${Api}chat/waitlist/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, name }),
      });
      
      setSubmitted(true);
      setTimeout(() => {
        setShowModal(false);
        setSubmitted(false);
      }, 2000);
    } catch (error) {
      console.error("Error signing up:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex items-end gap-3 mb-4">
    
      
      <div className="max-w-[100%]">
        
          <TypeWriter text={unavailableMessage} speed={30} />
          <button
            onClick={() => setShowModal(true)}
            className="bg-purple-600 text-white px-4 py-2 rounded-lg text-sm hover:bg-purple-700 transition-colors mt-3"
          >
            Join Waitlist
          </button>


        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-96 relative">
              <button
                onClick={() => setShowModal(false)}
                className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
              >
                X
              </button>

              {submitted ? (
                <div className="text-center py-8">
                  <div className="text-green-600 mb-2">✓</div>
                  <p className="text-gray-700">Thank you for joining the waitlist!</p>
                </div>
              ) : (
                <form onSubmit={handleSignup}>
                  <h3 className="text-xl font-semibold mb-4">Join our Waitlist</h3>
                  <p className="text-gray-600 mb-4">Be the first to know when we're available in your area.</p>
                  
                  <div className="mb-4">
                    <input
                      type="text"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 mb-4"
                      required
                    />
                    <input
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-purple-600 text-white py-2 rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50"
                  >
                    {isSubmitting ? (
                      <span className="flex items-center justify-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Submitting...
                      </span>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </form>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Unavailable;