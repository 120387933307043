import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  crypto: {
    btc: 0,
    eth: 0
  },
  positions: [],
  activeTrades: [],
  openOrders: []
};

export const perpSlice = createSlice({
  name: 'perps',
  initialState,
  reducers: {
    updatePrices: (state, action) => {
      state.crypto = action.payload;
    },
    setPositions: (state, action) => {
      state.positions = action.payload; 
    },
    setActiveTrades: (state, action) => {
      state.activeTrades = action.payload;
    },
    setOpenOrders: (state, action) => {
      state.openOrders = action.payload;
    }
  }
});

export const { updatePrices, setPositions, setActiveTrades, setOpenOrders } = perpSlice.actions;
export default perpSlice.reducer;