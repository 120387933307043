import { useState } from "react";
import Logo from "./logo";
const ComparisonTable = ({ data }) => {
  const [expandedRows, setExpandedRows] = useState({});
  
  const hasOurSiteData = data?.oursite?.length > 0;
  const hasAirbnbData = data?.airbnb?.length > 0;
  
  if (!data || (!hasOurSiteData && !hasAirbnbData)) return null;

  const toggleExpand = (id) => {
    setExpandedRows(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };
 console.log(data,"Hotel data")
  return (
    <div className="my-4 bg-white rounded-xl shadow-lg border border-gray-200 max-w-[330px] animate-fadeIn">
      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .animate-fadeIn {
          animation: fadeIn 0.5s ease-out forwards;
        }
      `}</style>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse table-fixed">
          <thead>
            <tr className="bg-gradient-to-r from-[#6B349A] to-[#4C37C3] text-white">
              <th className="w-14 px-1 py-2 text-left text-xs font-semibold">Source</th>
              <th className="w-[65px] px-1  py-2 text-left text-xs font-semibold">Hotel</th>
              <th className="w-20 px-0.5 py-1 text-left   text-xs font-semibold">Price</th>
              <th className="w-16 px-1 py-2 text-left text-xs font-semibold">Book</th>
            </tr>
          </thead>
          <tbody>
            {hasOurSiteData && data.oursite.map((hotel, index) => (
              <tr
                key={`oursite-${index}`}
                className="border-b hover:bg-gray-50 transition-colors"
              >
                <td className="px-1 text-center py-2 text-sm font-medium text-blue-600">
                  <Logo />
                </td>
                <td className="px-1 py-2">
                  <img src={hotel.src} alt={hotel.hotelname} className="w-16 h-16 object-cover mb-1" />
                  <div className={`text-[10px] ${expandedRows[`oursite-${index}`] ? '' : 'line-clamp-3'} break-words`}>
                    {hotel.hotelname}
                  </div>
                  {hotel.hotelname.length > 35 && (
                    <button
                      onClick={() => toggleExpand(`oursite-${index}`)}
                      className="text-blue-600 text-[10px] hover:underline"
                    >
                      {expandedRows[`oursite-${index}`] ? '−' : '+'}
                    </button>
                  )}
                </td>
                <td className="px-1 py-2 text-sm  whitespace-nowrap">
                  {hotel.price}
                </td>
                <td className="px-1 py-2 ">
                  <a
                    href={hotel.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-xs px-2 py-1 bg-gradient-to-r from-[#6B349A] to-[#4C37C3] text-white rounded inline-block"
                  >
                    Book
                  </a>
                </td>
              </tr>
            ))}
           
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComparisonTable;