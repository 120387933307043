import React, { useEffect, useState } from "react";
// import { Loader2 } from "lucide-react";
import Chatboticon from "./chatboticon";
import SendIcon from "./sendIcon";
import Stars from "./stars";
export const WelcomeScreen = ({ onSendMessage, onClose }) => {
  const [input, setInput] = useState('');
  const [currentTime,setCurrentTime] = useState('');
  const [isExiting,setIsExiting] = useState(false);
 
  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      setCurrentTime(now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    };
    updateTime();
    const timer = setInterval(updateTime, 1000); 
    return () => clearInterval(timer); 
  }, []);

  const handleSend = () => {
    if (input.trim()) {
      onSendMessage(input);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  const handleQuickAction = (text) => {
    onSendMessage(text);
  };

  const handleClose = () =>{
     setIsExiting(true);
     setTimeout(()=>{
      onClose();
     },300)
  }
  const quickActions = [
    { icon: <Stars/>, text: 'Find a place to stay' },
    { icon: <Stars/>, text: 'Help with my booking' },
    { icon: <Stars/>, text: 'Find new special offers' },
    { icon: <Stars/>, text: 'List my property' }
    /*
    "Find a place to stay"
"Help with my booking"
"Find new special offers"
"List my property"
    */
  ];

  return (
    <div className={`h-fit  px-4 py-2 transition-all duration-300 ease-in-out transform ${
      isExiting ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
    }`}>      <div className=" bg-gradient-to-r from-[#6B349A] to-[#4C37C3] rounded-[2rem]">
        <div className="p-3 flex flex-col items-center text-center">
          <button
            className="ml-auto w-8 h-8 rounded-full bg-purple-500 flex items-center justify-center"
            onClick={onClose}
          >
            <span className="text-white text-lg">-</span>
          </button>
          <div className="w-16 h-16  rounded-full bg-white flex items-center justify-center mb-2">
            <Chatboticon/>
          </div>
          <div className="text-2xl font-medium text-white">Coded-AI</div>
          <div className="text-sm text-white/90">You can ask anything what you want</div>
        </div>

        <div className="p-3">
        <div className="bg-white h-full flex-1 rounded-3xl p-4">
  <div className="flex justify-center items-center mb-4 relative">
    
    <div className="absolute top-1/2 left-0 right-0 h-[2px] bg-[#F6F6F6] transform -translate-y-1/2 z-0"></div>

  
    <div className="px-6 py-1 border-2 border-[#F6F6F6] bg-[#F6F6F6] text-gray-500 rounded-full text-gray-600 text-sm bg-white relative flex items-center z-10">
      Today: <span className="ml-2">{currentTime}</span>
    </div>
  </div>


            <div className="flex items-start gap-3 mb-6">
              <div className="w-6 h-6 rounded-full flex justify-center mt-14 items-center text-xs">
                <Chatboticon className="h-20"/>
              </div>
              <div className="bg-white rounded-xl max-w-[90%] shadow-lg p-4">
                <p className="text-gray-800">Hi There, Coded-AI is here!</p>
                <p className="text-gray-800">How can I help you today?</p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-3 mb-6">
              {quickActions.map((button) => (
                <button
                  key={button.text}
                  onClick={() => handleQuickAction(button.text)}
                  className="border-2 border-[#6C3CE9] text-[#6C3CE9] rounded-full py-2.5 px-4 text-sm hover:bg-[#6C3CE9]/5 transition-colors flex items-center justify-center gap-2"
                >
                  <span className="text-[#6C3CE9]">{button.icon}</span>
                  {button.text}
                </button>
              ))}
            </div>

            <div className="flex">
              <div className="flex-1">
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  className="w-full rounded-2xl h-full border shadow-md p-2"
                  placeholder="Write your message..."
                />
              </div>

              <button
                onClick={handleSend}
                className="w-12 h-12 pb-4"
              >
                <SendIcon/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
