import React from 'react'

function MarketIcon() {
  return (
    <div>  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 10.5001H2C1.724 10.5001 1.5 10.2761 1.5 10.0001V7.00006C1.5 6.72406 1.724 6.50006 2 6.50006H3C3.276 6.50006 3.5 6.72406 3.5 7.00006V10.0001C3.5 10.2761 3.276 10.5001 3 10.5001Z"
      fill="white"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.50049 8.5H7.66699C7.88349 8.5 8.09399 8.43 8.26699 8.3L9.47499 7.394C9.77649 7.168 10.1985 7.198 10.465 7.4645C10.761 7.7605 10.761 8.24 10.465 8.5355L9.42849 9.572C9.14949 9.851 8.79349 10.0415 8.40649 10.119L6.94149 10.412C6.65099 10.47 6.35149 10.463 6.06399 10.391L4.73899 10.06C4.58049 10.02 4.41799 10 4.25449 10H3.50049"
      fill="white"
    />
    <path
      d="M6.50049 8.5H7.66699C7.88349 8.5 8.09399 8.43 8.26699 8.3L9.47499 7.394C9.77649 7.168 10.1985 7.198 10.465 7.4645C10.761 7.7605 10.761 8.24 10.465 8.5355L9.42849 9.572C9.14949 9.851 8.79349 10.0415 8.40649 10.119L6.94149 10.412C6.65099 10.47 6.35149 10.463 6.06399 10.391L4.73899 10.06C4.58049 10.02 4.41799 10 4.25449 10H3.50049M3.50049 9.95588V9.00001V7.74728L4.2001 7.20001H4.8001L6.45273 8.5"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00049 7.74256C8.00049 8.16106 7.66149 8.50006 7.24299 8.50006H6.28099L4.8001 9.60004H3.6001L3.50049 7.50006L4.40899 6.89456C4.79449 6.63706 5.24799 6.50006 5.71149 6.50006C5.90349 6.50006 6.09449 6.52356 6.28099 6.57006L7.42699 6.85656C7.76399 6.94056 8.00049 7.24356 8.00049 7.59106V7.74256Z"
      fill="white"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.3155 2.1875C7.269 1.905 7.14 1.6335 6.922 1.4155C6.368 0.8615 5.4695 0.8615 4.9155 1.4155C4.3615 1.9695 4.3615 2.868 4.9155 3.422C5.2605 3.767 5.7385 3.8965 6.184 3.812"
      fill="white"
    />
    <path
      d="M7.3155 2.1875C7.269 1.905 7.14 1.6335 6.922 1.4155C6.368 0.8615 5.4695 0.8615 4.9155 1.4155C4.3615 1.9695 4.3615 2.868 4.9155 3.422C5.2605 3.767 5.7385 3.8965 6.184 3.812L7.3155 2.1875Z"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.58401 2.5776C9.13816 3.13176 9.13816 4.03022 8.58401 4.58437C8.02985 5.13853 7.13139 5.13853 6.57724 4.58437C6.02308 4.03022 6.02308 3.13176 6.57724 2.5776C7.13139 2.02345 8.02985 2.02345 8.58401 2.5776Z"
      fill="white"
    />
    <path
      d="M8.58401 2.5776C9.13816 3.13176 9.13816 4.03022 8.58401 4.58437C8.02985 5.13853 7.13139 5.13853 6.57724 4.58437C6.02308 4.03022 6.02308 3.13176 6.57724 2.5776C7.13139 2.02345 8.02985 2.02345 8.58401 2.5776"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg></div>
  )
}

export default MarketIcon