import {
  ArrowToLeft,
  ArrowToRightIcon,
  GridLayoutIconRent,
  MapLineIconRent,
  NUSDIcon,
  PinLocationIconRent,
  ShortRentalIcon,
} from "../../AssetComponents/Images";
import { Popover } from "react-tiny-popover";
import { useState, useEffect } from "react";
import { RentalItem } from "../../Components/RealEstateProperty/RentalItem";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { updateToken } from "../../Components/functions/Functions";
import { useSelector } from "react-redux";
import { mapStyles } from "../../Components/GoogleMap/Style";
import {
  MapShareIcon,
  MapPlusIcon,
  MapMinusIcon,
} from "../../AssetComponents/Images";
import { GoogleMap, OverlayView, Marker } from "@react-google-maps/api";
import mapPinIcon from "../../assets/images/Dashboard/Dashboard/mappin.svg";
import "react-range-slider-input/dist/style.css";
import {
  setPriceRange,
} from "../../ReduxSlices/SearchSlice";

import { api } from "../../Components/functions/Api";
import { RentalNavigation } from "../../Components/Rent/RentalNavigationComponent";
import { RentalSearch } from "../../Components/Rent/Search";
import Listings from "../../Components/Rent/Listings";
import HomeIcons from "../../Components/Rent/HomeIcons";
import Filter from "../../Components/Rent/Filter";

export const Rent = () => {
  const [showMapMenu, setShowMapMenu] = useState(false);
  const navigate = useNavigate();
  const account = useSelector((state) => state.auth.account);
  const reservation_period = useSelector((state) => state.reservation.period);
  const who = useSelector((state) => state.reservation.who);
  const guests = useSelector((state) => state.reservation.guests);
  const [metaDetails, setMetaDetails] = useState({
    amenities: {},
  });
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [sortType, setSortType] = useState('default');

  // const defaultPeriod = [
  //   {
  //     startDate: reservation_period.start
  //       ? new Date(reservation_period.start * 1000)
  //       : new Date(),
  //     endDate: reservation_period.end
  //       ? new Date(reservation_period.end * 1000)
  //       : new Date(),
  //     key: "selection",
  //     color: "#5b1deeaa",
  //   },
  // ];
  // const [period, setPeriod] = useState(defaultPeriod);
  const [openFilter, setOpenFilter] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  // const testNet = Testnet(1);
  const [zoom, setZoom] = useState(10);
  const [currentCategory, setCurrentCategory] = useState("Bathroom");
  const [activeToken, setActiveToken] = useState();
  const [fullScreen, setFullScreen] = useState(false);
  const [hideMap, setHideMap] = useState(false);
  const geocoder = new window.google.maps.Geocoder();
  const range = useSelector((state) => state.search.priceRange);
  const [pinLocation, setPinLocation] = useState({
    lat: 25,
    lng: 55,
  });

  const handleSelectMapPin = async (pinLocation) => {
    // setAddressInput(res.results[0].formatted_address);
  };

  useEffect(() => {
    handleSelectMapPin(pinLocation);
  }, [pinLocation]);

  useEffect(() => {
    if (!range?.max)
      dispatch(
        setPriceRange({
          min: range?.min,
          max: 1000,
        })
      );
    if (!range?.min)
      dispatch(
        setPriceRange({
          min: 1,
          max: range?.max,
        })
      );
  }, [range]);



  const getProperties = async () => {
    try {
      const res = await api("property/getProperties", {});
      // Ensure we're setting an array
      setProperties(Array.isArray(res) ? res : [res]);
    } catch (error) {
      console.error("Failed to fetch properties", error);
      setProperties([]);
    }
  };

  useEffect(() => {
    getProperties();
  }, []);


  const handleSearch = async (searchResults) => {
    console.log('Search results received:', searchResults); // Debug log
    
    if (searchResults && searchResults.length > 0) {
      setProperties(searchResults);
      
      // If search includes location, update pin location
      if (searchResults[0]?.metaData?.location) {
        setPinLocation({
          lat: searchResults[0].metaData.location.lat,
          lng: searchResults[0].metaData.location.lng
        });
      }
    } else {
      setProperties([]);
    }
  };

  useEffect(() => {
    const filtered = properties.filter((prop) => {
      // Filter by location (if provided)
      const locationMatch = !location || 
        (prop.metaData?.location?.lat === location.lat && 
         prop.metaData?.location?.lng === location.lng);
  
      // Filter by guests (if provided)
      const guestsMatch = !guests || prop.short?.max_guests >= guests;
  
      // Filter by period (if provided)
      // You would need to implement additional logic for date filtering
      // const periodMatch = !period || checkDateAvailability(prop, period);
  
      return locationMatch && guestsMatch; // && periodMatch;
    });
  
    setFilteredProperties(filtered);
  }, [properties, reservation_period, who, guests, location, pinLocation, range, sortType]); 

  const handleFilterChange = (filteredResults) => {
    setFilteredProperties(filteredResults);
  };

  const handleSort = (sortedData) => {
    // Update both main and filtered if needed
    setProperties(sortedData);
    setFilteredProperties(sortedData);
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex items-center justify-between px-[16px] my-[20px]">
       <RentalNavigation/>
       <RentalSearch onSearch={handleSearch}/>
          <div className="flex items-center gap-[10px]">
          <Filter 
  properties={properties}
  onFilterChange={handleFilterChange}
/>
          
<Listings 
  onSort={handleSort}
  setSortType={setSortType}
/>
          </div>
        </div>
        {(location.pathname.split("/")[2] === "short" ||
          location.pathname.split("/")[2] === "all") && (
          <div className="w-full h-full flex max-h-[calc(100vh-200px)]">
            <div
              className={
                fullScreen
                  ? "w-0 hidden"
                  : hideMap
                  ? "w-full flex flex-col h-full"
                  : "w-max flex flex-col h-full"
              }
            >
              <div className="grid grid-cols-3 items-center w-full">
                <div className="flex items-center">
                  <ShortRentalIcon />
                  <div className="text-[24px] font-semibold min-w-[300px]">
                    Short-Term Rental
                  </div>
                </div>
                <Popover
                  isOpen={showMapMenu}
                  positions={"bottom"}
                  onClickOutside={() => setShowMapMenu(false)}
                  content={
                    <div
                      onClick={() => setShowMapMenu(false)}
                      className="mt-[10px] bg-white rounded-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
                    >
                      {!fullScreen && !hideMap && (
                        <div
                          onClick={() => setHideMap(true)}
                          className="cursor-pointer hover:bg-[#f5f5f5] rounded-t-[10px] p-[8px] gap-[8px] flex items-center w-full"
                        >
                          <GridLayoutIconRent />
                          <div className="text-[#666666]">Hide Map</div>
                        </div>
                      )}
                      {!fullScreen && hideMap && (
                        <div
                          onClick={() => setHideMap(false)}
                          className="cursor-pointer hover:bg-[#f5f5f5] rounded-t-[10px] p-[8px] gap-[8px] flex items-center w-full"
                        >
                          <GridLayoutIconRent />
                          <div className="text-[#666666]">Show Map</div>
                        </div>
                      )}

                      <div
                        onClick={() => {
                          setHideMap(false);
                          setFullScreen(true);
                        }}
                        className="cursor-pointer hover:bg-[#f5f5f5] rounded-b-[10px] p-[8px] gap-[8px] flex items-center w-full"
                      >
                        <PinLocationIconRent />
                        <div className="text-[#666666]">View Full Map</div>
                      </div>
                    </div>
                  }
                >
                  <div
                    onClick={() => setShowMapMenu(true)}
                    className="justify-self-center hover:bg-[#f6f6f6] select-none w-max py-[4px] cursor-pointer bg-white rounded-[10px] px-[14px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
                  >
                    <MapLineIconRent />
                    <div className="font-semibold">View Map</div>
                  </div>
                </Popover>
              </div>

              <div
                className={
                  hideMap
                    ? "w-full flex flex-wrap py-[10px] gap-4 overflow-auto h-[calc(100vh-270px)] px-[20px]"
                    : "w-max grid 2xl:grid-cols-3 xl:grid-cols-2 lg:grid-cols-1 sm:grid-cols-1 py-[10px] gap-4 overflow-auto h-[calc(100vh-270px)] px-[20px]"
                }
              >
             
                {/* {properties?.map((nft, index) => {
                  if (nft?.access?.owner !== account && nft?.short?.islisted)
                    return (
                      <>
                        <div
                          className={
                            activeToken === nft?.token_id
                              ? "w-max h-max translate-y-[-4px]"
                              : "w-max h-max"
                          }
                          onMouseEnter={() => setActiveToken(nft?.token_id)}
                          onMouseLeave={() => setActiveToken(null)}
                          key={index}
                        >
                          <RentalItem
                            token_id={nft?.token_id}
                            tokenData={nft}
                          />
                        </div>
                      </>
                    );
                })}
                 */}
                 {Array.isArray(properties) && properties.map((nft, index) => {
  if (nft?.access?.owner !== account && nft?.short?.islisted) {
    return (
      <div
        key={index}
        className={
          activeToken === nft?.token_id
            ? "w-max h-max translate-y-[-4px]"
            : "w-max h-max"
        }
        onMouseEnter={() => setActiveToken(nft?.token_id)}
        onMouseLeave={() => setActiveToken(null)}
      >
        <RentalItem
          token_id={nft?.token_id}
          tokenData={nft}
        />
      </div>
    );
  }
  return null;
})}
              </div>
            </div>
            <GoogleMap
              center={pinLocation}
              zoom={zoom}
              mapContainerClassName={
                hideMap
                  ? "w-0"
                  : "relative w-full mx-[10px] h-full rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
              }
              options={{
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: false,
                styles: mapStyles,
              }}
            >
              <div className="absolute top-[20px] left-[20px]">
                <div
                  onClick={() => setFullScreen(!fullScreen)}
                  className="p-[4px] rounded-full shadow-md bg-white cursor-pointer hover:bg-[#f6f6f6]"
                >
                  {fullScreen ? <ArrowToRightIcon /> : <ArrowToLeft />}
                </div>
              </div>
              <div className="absolute top-[20px] right-[20px] flex items-center gap-[10px]">
                <div className="p-[4px] rounded-full hover:bg-[#000000] shadow-md bg-gradient-to-b from-[#202020c0] from-0% to-70% to-[#202020]">
                  <MapShareIcon />
                </div>
                <div
                  className="p-[4px] rounded-full shadow-md bg-white hover:bg-[#f6f6f6] cursor-pointer"
                  onClick={() => setZoom(zoom + 1)}
                >
                  <MapPlusIcon />
                </div>
                <div
                  className="p-[4px] rounded-full shadow-md bg-white hover:bg-[#f6f6f6] cursor-pointer"
                  onClick={() => setZoom(zoom - 1)}
                >
                  <MapMinusIcon />
                </div>
              </div>
              {properties?.map((nft, index) => {
                if (nft?.access?.owner !== account && nft?.short?.islisted)
                  return (
                    <OverlayView
                      position={nft?.metaData?.location}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      key={index}
                    >
                      <div>
                        <div
                          onClick={() => {
                            navigate(location.pathname + "/" + nft?.token_id);
                          }}
                          onMouseEnter={() => setActiveToken(nft?.token_id)}
                          onMouseLeave={() => setActiveToken(null)}
                          className={
                            nft?.token_id === activeToken
                              ? "w-max px-[12px] py-[6px] rounded-[12px] bg-gradient-to-b from-[#202020c0] from-0% to-70% to-[#202020] text-white text-center cursor-pointer shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
                              : "w-max px-[12px] py-[6px] rounded-[12px] bg-gradient-to-b from-[#5b1deec0] hover:from-[#202020c0] from-0% to-70% to-[#5b1dee] hover:to-[#202020] text-white text-center cursor-pointer shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
                          }
                        >
                          <div className="flex items-center gap-[4px]">
                            <NUSDIcon className="w-[20px]" />
                            <div className="text-[16px]">
                              ${nft?.short?.price_per_day}
                            </div>
                          </div>
                        </div>
                        {nft?.token_id === activeToken && (
                          <div className="scale-x-[0.6] scale-y-[0.5] left-[-50px] origin-top z-[200] absolute">
                            <RentalItem
                              token_id={nft?.token_id}
                              confetti={false}
                              tokenData={nft}
                            />
                          </div>
                        )}
                      </div>
                    </OverlayView>
                  );
                // else return <></>;
              })}
              <Marker
                position={pinLocation}
                draggable
                onDragEnd={(e) => {
                  setPinLocation({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  });
                }}
                icon={{
                  url: mapPinIcon,
                  scaledSize: new window.google.maps.Size(80, 80),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(40, 68),
                }}
              />
            </GoogleMap>
          </div>
        )}
        {location.pathname.split("/")[2] === "long" && (
          <div className="w-full h-full flex-col items-center justify-center flex max-h-[calc(100vh-200px)]">
            <HomeIcons/>
            <div className="text-[#202020]">Coming soon</div>
          </div>
        )}
      </div>
    </div>
  );
};
