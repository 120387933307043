import React from 'react'

function BuyIcon() {
  return (
    <div><svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2615_14715)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9.5H2C1.4475 9.5 1 9.0525 1 8.5V3.5C1 2.9475 1.4475 2.5 2 2.5H10C10.5525 2.5 11 2.9475 11 3.5V8.5C11 9.0525 10.5525 9.5 10 9.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9.5H2C1.4475 9.5 1 9.0525 1 8.5V3.5C1 2.9475 1.4475 2.5 2 2.5H10C10.5525 2.5 11 2.9475 11 3.5V8.5C11 9.0525 10.5525 9.5 10 9.5Z"
        stroke="#323232"
        stroke-width="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.88388 5.11612C7.37204 5.60427 7.37204 6.39573 6.88388 6.88388C6.39573 7.37204 5.60427 7.37204 5.11612 6.88388C4.62796 6.39573 4.62796 5.60427 5.11612 5.11612C5.60427 4.62796 6.39573 4.62796 6.88388 5.11612Z"
      fill="white"
    />
    <path
      d="M6.88388 5.11612C7.37204 5.60427 7.37204 6.39573 6.88388 6.88388C6.39573 7.37204 5.60427 7.37204 5.11612 6.88388C4.62796 6.39573 4.62796 5.60427 5.11612 5.11612C5.60427 4.62796 6.39573 4.62796 6.88388 5.11612"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.9995 5.8125C8.896 5.813 8.8125 5.897 8.8125 6.0005C8.8125 6.104 8.8965 6.188 9 6.1875C9.1035 6.1875 9.1875 6.1035 9.1875 6C9.1875 5.8965 9.1035 5.8125 8.9995 5.8125Z"
      fill="white"
    />
    <path
      d="M8.9995 5.8125C8.896 5.813 8.8125 5.897 8.8125 6.0005C8.8125 6.104 8.8965 6.188 9 6.1875C9.1035 6.1875 9.1875 6.1035 9.1875 6C9.1875 5.8965 9.1035 5.8125 8.9995 5.8125"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.9995 5.8125C2.896 5.813 2.8125 5.897 2.8125 6.0005C2.8125 6.104 2.8965 6.188 3 6.1875C3.1035 6.1875 3.1875 6.1035 3.1875 6C3.1875 5.8965 3.1035 5.8125 2.9995 5.8125Z"
      fill="white"
    />
    <path
      d="M2.9995 5.8125C2.896 5.813 2.8125 5.897 2.8125 6.0005C2.8125 6.104 2.8965 6.188 3 6.1875C3.1035 6.1875 3.1875 6.1035 3.1875 6C3.1875 5.8965 3.1035 5.8125 2.9995 5.8125"
      stroke="#323232"
      stroke-width="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_d_2615_14715"
        x="0.600098"
        y="2.09998"
        width="11.7998"
        height="8.80005"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.196078 0 0 0 0 0.196078 0 0 0 0 0.196078 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2615_14715"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2615_14715"
          result="shape"
        />
      </filter>
    </defs>
  </svg></div>
  )
}

export default BuyIcon