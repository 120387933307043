import React, { useState } from 'react';
import { Popover } from "react-tiny-popover";
import { 
  SortIconRentCe, 
  SortIconRentDec, 
  RatingIconRent, 
  ArrowIconRent 
} from "../../AssetComponents/Images";
import { api } from '../functions/Apiwithouttoken';
function Listings({ onSort, setSortType }) {
  const [showOrderMenu, setShowOrderMenu] = useState(false);
  const [currentSort, setCurrentSort] = useState('Sort Listings');

  const handleListingTypeSort = async (type) => {
    try {
      setSortType(type);
      setShowOrderMenu(false);

      // Update button text based on sort type
      switch(type) {
        case 'lowToHigh':
          setCurrentSort('Price: Low to High');
          break;
        case 'highToLow':
          setCurrentSort('Price: High to Low');
          break;
        case 'rating':
          setCurrentSort('Rating');
          break;
        default:
          setCurrentSort('Sort Listings');
      }

      // Call the new backend route for listing types
      const sortedProperties = await api('property/getListingTypes', { 
        listingType: type 
      });

      // Instead of directly calling setProperties, call the parent callback
      if (onSort) onSort(sortedProperties);
    } catch (error) {
      console.error('Error sorting properties:', error);
      // Optional: Add error handling or toast notification
    }
  };

  return (
    <Popover
      isOpen={showOrderMenu}
      positions={["bottom"]}
      onClickOutside={() => setShowOrderMenu(false)}
      content={
        <div
          className="mt-[10px] bg-white rounded-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,257,0.8)]"
        >
          <div 
            onClick={() => handleListingTypeSort('lowToHigh')} 
            className="cursor-pointer hover:bg-[#f5f5f5] rounded-t-[10px] p-[8px] gap-[8px] flex items-center w-full"
          >
            <SortIconRentCe />
            <div className="text-[#666666]">Price: Low to High</div>
          </div>
          <div 
            onClick={() => handleListingTypeSort('highToLow')} 
            className="cursor-pointer hover:bg-[#f5f5f5] p-[8px] gap-[8px] flex items-center w-full"
          >
            <SortIconRentDec />
            <div className="text-[#666666]">Price: High to Low</div>
          </div>
          <div 
            onClick={() => handleListingTypeSort('rating')} 
            className="cursor-pointer hover:bg-[#f5f5f5] rounded-b-[10px] p-[8px] gap-[8px] flex items-center w-full"
          >
            <RatingIconRent />
            <div className="text-[#666666]">Rating</div>
          </div>
        </div>
      }
    >
      <div
        onClick={() => setShowOrderMenu(true)}
        className="py-[4px] cursor-pointer bg-white hover:bg-[#f6f6f6] rounded-[10px] px-[14px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,257,0.8)]"
      >
        <div className="text-[#5b1dee] font-semibold">{currentSort}</div>
        <ArrowIconRent />
      </div>
    </Popover>
  );
}

export default Listings;