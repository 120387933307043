import React,{useState,useEffect} from 'react'
import { Popover } from 'react-tiny-popover'   
import { DateRangePicker } from 'react-date-range'
import {SelectionGroup,SelectionItem} from "../Selection/Selection"
import {PurpleButton} from "../Buttons/PurpleButton"
import {NumberSpin} from "../Spin/NumberSpin"
import {SearchNormalIcon,PinIcon} from "../../AssetComponents/Images"
import { useDispatch,useSelector } from 'react-redux'
import {setWho,setReservationPeriod} from "../../ReduxSlices/ReservationSlice"
import { api } from '../functions/Apiwithouttoken'

export const RentalSearch = ({onSearch})=>{
    const [searchPop, setSearchPop] = useState(false);
  const [searchItem, setSearchItem] = useState(-1);
  const [predictList, setPredictList] = useState([]);
  const [addressInput, setAddressInput] = useState("");
  const [period, setPeriod] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#5b1deeaa",
    },
  ]);
  const dispatch = useDispatch();
  const reservation_period = useSelector((state) => state.reservation.period);
  const who = useSelector((state) => state.reservation.who);
  const guests = useSelector((state) => state.reservation.guests);

  const service = new window.google.maps.places.AutocompleteService();
  const geocoder = new window.google.maps.Geocoder();

  const getAddressList = async (input) => {
    if (!input) {
      setPredictList([]);
      return;
    }
    
    service.getPlacePredictions(
      { input: input },
      function (predictions, status) {
        if (
          status !== window.google.maps.places.PlacesServiceStatus.OK ||
          !predictions
        ) {
          setPredictList([]);
          return;
        }
        setPredictList(predictions);
      }
    );
  };

  const handleSelectFromAddressList = async (place_id, description) => {
    const res = await geocoder.geocode({ placeId: place_id });
    if (res.results[0]) {
      const location = {
        lat: res.results[0].geometry.location.lat(),
        lng: res.results[0].geometry.location.lng(),
        address: description // Store the address text
      };
      setAddressInput(description);
      setSearchItem(1);
      return location;
    }
    return null;
  };
  
  useEffect(() => {
    getAddressList(addressInput);
  }, [addressInput]);

  useEffect(() => {
    dispatch(
      setReservationPeriod({
        start: Math.floor(new Date(period[0].startDate).getTime() / 1000),
        end: Math.floor(new Date(period[0].endDate).getTime() / 1000),
      })
    );
  }, [period]);
  const handleSearch = async () => {
    try {
      // First get location coordinates if address is entered
      let locationData = null;
      if (addressInput) {
        const res = await geocoder.geocode({ address: addressInput });
        if (res.results[0]) {
          locationData = {
            lat: res.results[0].geometry.location.lat(),
            lng: res.results[0].geometry.location.lng()
          };
        }
      }
  
      const searchParams = {
        location: locationData || { lat: 25.2416543, lng: 55.2522821 },
        period: {
          startDate: period[0].startDate.toISOString().split('T')[0],
          endDate: period[0].endDate.toISOString().split('T')[0]
        },
        guests: {
          adults: who.adults || 2,
          children: who.children || 0,
          infants: who.infants || 0,
          pets: who.pets || 0
        },
        radius: 3000
      };
  
      console.log('Detailed Search params:', JSON.stringify(searchParams, null, 2)); // More detailed logging
  
      // Use JSON.stringify to ensure proper payload formatting
      const response = await api("property/searchProperties", {
        method: 'POST', // Explicitly set method to POST
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(searchParams)
      });
  
      const propertiesArray = Array.isArray(response) ? response : [response];
      onSearch(propertiesArray);
    } catch (error) {
      console.error("Search failed:", error);
      onSearch([]);
    }
  };

  return (
    <Popover
      isOpen={searchPop}
      positions={["bottom"]}
      onClickOutside={() => {
        setSearchPop(false);
        setSearchItem(-1);
        setPredictList([]);
      }}
      containerStyle={{ zIndex: 40 }}  // Add z-index to Popover
      content={
        <div className="mt-[10px] z-40 bg-white rounded-[10px] w-max h-max shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
          {searchItem === 0 && predictList?.map((address) => (
            <div
              key={address.place_id}
              onClick={() => handleSelectFromAddressList(address.place_id, address.description)}
              className="w-[400px] bg-white flex gap-[10px] items-center cursor-pointer overflow-hidden h-[50px] p-[8px] rounded-[8px] hover:bg-gray-300"
            >
              <PinIcon />
              <div className="w-full truncate">{address.description}</div>
            </div>
          ))}
          {(searchItem === 1 || searchItem === 2) && (
            <DateRangePicker
              onChange={(item) => setPeriod([item.selection])}
              months={2}
              ranges={period}
              direction="horizontal"
              minDate={new Date()}
              color="#5b1dee"
              startDatePlaceholder="Check In"
              endDatePlaceholder="Check Out"
              className="my-[20px]"
              showDateDisplay={false}
            />
          )}
          {searchItem === 3 && (
            <div className="w-[340px] p-[20px] space-y-[10px]">
              {/* Guest selection content */}
              <div className="flex items-center justify-between">
                <div>
                  <div className="font-semibold">Adults</div>
                  <div className="text-[#959595]">Age 13 or above</div>
                </div>
                <NumberSpin
                  value={who.adults || 1}
                  onChange={(value) =>
                    dispatch(setWho({ ...who, adults: value }))
                  }
                  min={1}
                />
              </div>
                     <div className="flex items-center justify-between ">
                      <div>
                        <div className="font-semibold">Children</div>
                        <div className="text-[#959595]">Age 2-12</div>
                      </div>
                      <div className="flex items-center">
                        <NumberSpin
                          value={who.children || 0}
                          onChange={(value) =>
                            dispatch(
                              setWho({
                                ...who,
                                children: value,
                              })
                            )
                          }
                          min={0}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between ">
                      <div>
                        <div className="font-semibold">Infants</div>
                        <div className="text-[#959595]">Under 2</div>
                      </div>
                      <div className="flex items-center">
                        <NumberSpin
                          value={who.infants || 0}
                          onChange={(value) =>
                            dispatch(
                              setWho({
                                ...who,
                                infants: value,
                              })
                            )
                          }
                          min={0}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between ">
                      <div>
                        <div className="font-semibold">Pets</div>
                        <div className="text-[#959595] underline">
                          Bringing a service animal?
                        </div>
                      </div>
                      <div className="flex items-center">
                        <NumberSpin
                          value={who.pets || 0}
                          onChange={(value) =>
                            dispatch(
                              setWho({
                                ...who,
                                pets: value,
                              })
                            )
                          }
                          min={0}
                        />
                      </div>
                    </div>
                </div>
          )}
        </div>
      }
    >
      <div className="flex items-center h-max w-max bg-white border-[2px] gap-[1px] border-[#e3e3e3] rounded-[14px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
        <div onClick={() => setSearchPop(true)}>
          <SelectionGroup
            SelectedItemMask="rounded-[10px] bg-[#e3e3e3]"
            defaultItem={searchItem}
            className="gap-[2px] flex items-center w-max rounded-[14px]"
          >
            {/* Location Selection */}
            <SelectionItem
              UnselectedItem={
                <div
                  onClick={() => setSearchItem(0)}
                  className="cursor-pointer hover:bg-[#f6f6f6] space-y-[4px] py-[8px] px-[20px] bg-white rounded-[10px]"
                >
                  <div className="text-[16px]">Where</div>
                  <input
                    placeholder="Search destinations"
                    className="outline-none font-normal truncate"
                    value={addressInput}
                    onChange={(e) => setAddressInput(e.target.value)}
                  />
                </div>
              }
              SelectedItem={
                <div className="cursor-pointer hover:bg-[#f6f6f6] space-y-[4px] py-[8px] px-[20px] bg-[#e3e3e3] rounded-[10px] shadow-[2px_2px_1px_0px_rgba(187,195,206,0.6),-2px_-2px_2px_0px_rgba(253,255,255,0.8),0px_1px_2px_0px_rgba(0,0,0,0.1)]">
                  <div className="text-[16px]">Where</div>
                  <input
                    placeholder="Search destinations"
                    className="outline-none bg-[#e3e3e3] font-normal truncate"
                    value={addressInput}
                    onChange={(e) => setAddressInput(e.target.value)}
                  />
                </div>
              }
            />
             <SelectionItem
                    UnselectedItem={
                      <div
                        onClick={() => setSearchItem(1)}
                        className="space-y-[4px] hover:bg-[#f6f6f6] py-[8px] px-[20px] bg-white rounded-[10px]"
                      >
                        <div className="">Check in</div>
                        <div className="text-[#666666] font-normal truncate">
                          {reservation_period.start
                            ? new Date(
                                reservation_period.start * 1000
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })
                            : "Add Date"}
                        </div>
                      </div>
                    }
                    SelectedItem={
                      <div className="space-y-[4px] hover:bg-[#f6f6f6] py-[8px] px-[20px] bg-[#e3e3e3] rounded-[10px] shadow-[2px_2px_1px_0px_rgba(187,195,206,0.6),-2px_-2px_2px_0px_rgba(253,255,255,0.8),0px_1px_2px_0px_rgba(0,0,0,0.1)]">
                        <div className="">Check in</div>
                        <div className="text-[#666666] font-normal truncate">
                          {reservation_period.start
                            ? new Date(
                                reservation_period.start * 1000
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })
                            : "Add Date"}
                        </div>
                      </div>
                    }
                  />
                  <SelectionItem
                    UnselectedItem={
                      <div
                        onClick={() => setSearchItem(2)}
                        className="space-y-[4px] hover:bg-[#f6f6f6] py-[8px] px-[20px] bg-white w-[120px] rounded-[10px]"
                      >
                        <div className="">Check out</div>
                        <div className="text-[#666666] font-normal truncate">
                          {reservation_period.end
                            ? new Date(
                                reservation_period.end * 1000
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })
                            : "Add Date"}
                        </div>
                      </div>
                    }
                    SelectedItem={
                      <div className="space-y-[4px] hover:bg-[#f6f6f6] py-[8px] px-[20px] bg-[#e3e3e3] rounded-[10px] w-[120px] shadow-[2px_2px_1px_0px_rgba(187,195,206,0.6),-2px_-2px_2px_0px_rgba(253,255,255,0.8),0px_1px_2px_0px_rgba(0,0,0,0.1)]">
                        <div className="">Check out</div>
                        <div className="text-[#666666] font-normal truncate">
                          {reservation_period.end
                            ? new Date(
                                reservation_period.end * 1000
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })
                            : "Add Date"}
                        </div>
                      </div>
                    }
                  />
                  <SelectionItem
                    UnselectedItem={
                      <div
                        onClick={() => setSearchItem(3)}
                        className="space-y-[4px] hover:bg-[#f6f6f6] py-[8px] px-[20px] bg-white w-[120px] rounded-[10px]"
                      >
                        <div className="">Who</div>
                        <div className="text-[#666666] font-normal tr uncate">
                          {guests ? guests : "Add Guests"}
                        </div>
                      </div>
                    }
                    SelectedItem={
                      <div className="space-y-[4px] hover:bg-[#f6f6f6] py-[8px] px-[20px] bg-[#e3e3e3] rounded-[10px] w-[120px] shadow-[2px_2px_1px_0px_rgba(187,195,206,0.6),-2px_-2px_2px_0px_rgba(253,255,255,0.8),0px_1px_2px_0px_rgba(0,0,0,0.1)]">
                        <div className="">Who</div>
                        <div className="text-[#666666] font-normal truncate">
                          {guests ? guests : "Add Guests"}
                        </div>
                      </div>
                    }
                  />
          </SelectionGroup>
        </div>
        <div className="px-[10px]">
          <PurpleButton
            text={
              <div className="flex items-center gap-[10px]">
                <SearchNormalIcon />
                <div className="font-normal">Search</div>
              </div>
            }
            onClick={handleSearch}
          />
        </div>
      </div>
    </Popover>
  );
};