import React from 'react'

function Approal5() {
  return (
    <div><svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.418 8.99413L15.8321 6.58079L13.418 4.16663"
      stroke="#A4A4A4"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16602 6.58329H15.8327"
      stroke="#A4A4A4"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.58018 11.0057L4.16602 13.4191L6.58018 15.8332"
      stroke="#A4A4A4"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8327 13.4167H4.16602"
      stroke="#A4A4A4"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg></div>
  )
}

export default Approal5