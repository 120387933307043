import React from 'react'

function Chatboticon() {
  return (
    <div className='w-full h-full'>
<svg width="40" height="40" className='w-full h-full' viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_i_3559_50906)">
<g clip-path="url(#clip0_3559_50906)">
<rect width="40" height="40" rx="20" fill="white"/>
<rect x="7.29248" y="28.0352" width="25.524" height="14.5852" rx="7.29258" fill="#2B0F67"/>
<rect x="7.29248" y="28.0352" width="25.524" height="14.5852" rx="7.29258" fill="#2B0F67"/>
<path d="M24.0656 28.3994L21.5132 35.3274L26.618 31.6811L24.0656 28.3994Z" fill="#52378C"/>
<path d="M16.0437 28.3994L18.2314 35.3274L13.4913 31.6811L16.0437 28.3994Z" fill="#52378C"/>
<path d="M16.0435 28.7646H24.0653L20.5262 38.9743H19.3466L16.0435 28.7646Z" fill="white"/>
<path d="M19.3254 31.3164H20.7839L22.0592 34.5981L20.5269 38.9736H19.3472L17.9409 34.5981L19.3254 31.3164Z" fill="#6260C9"/>
<path d="M19.4572 27.3057H20.7486L21.8777 29.6617L20.521 31.6812H19.4766L18.2314 29.6617L19.4572 27.3057Z" fill="#423BA0"/>
<g filter="url(#filter1_d_3559_50906)">
<mask id="mask0_3559_50906"  maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20">
<circle cx="20" cy="20" r="10" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_3559_50906)">
<circle cx="20" cy="20" r="10" fill="#5C36AF"/>
<g filter="url(#filter2_f_3559_50906)">
<path d="M29.1007 31.9008C30.3007 19.3408 29.5008 12.4004 19.1008 8.90037C15.0008 2.40037 25.7007 5.50077 34.2007 14.9008C42.7007 24.3008 27.6007 47.6008 29.1007 31.9008Z" fill="#A760E7"/>
</g>
<g filter="url(#filter3_f_3559_50906)">
<circle cx="24.6003" cy="31.8" r="10.8" fill="#723CA2"/>
</g>
<g filter="url(#filter4_f_3559_50906)">
<path d="M21.9374 41.9435C13.7287 34.4913 19.1853 30.3868 22.6055 24.4353C26.2107 9.06504 3.4548 10.6228 4.01 36.8599C4.24669 48.0448 32.1982 51.2589 21.9374 41.9435Z" fill="#2B114B"/>
</g>
<g filter="url(#filter5_f_3559_50906)">
<path d="M18.8017 17.3012C10.8017 19.7012 13.5659 30.1994 14.9992 34.1994C8.19916 39.5996 -1.69823 30.0012 0.901771 22.0012C3.50177 14.0012 8.3536 10.7117 13.1017 10.0012C19.7992 8.99888 28.8017 14.3012 18.8017 17.3012Z" fill="#455FE4"/>
</g>
<g opacity="0.5" filter="url(#filter6_f_3559_50906)">
<ellipse cx="18.1116" cy="14.0796" rx="3.1242" ry="1.70594" transform="rotate(-7.10967 18.1116 14.0796)" fill="#C7D3FF"/>
</g>
</g>
<g clip-path="url(#clip1_3559_50906)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.8257 18.999C14.8257 18.2396 15.4413 17.624 16.2007 17.624C16.9601 17.624 17.5757 18.2396 17.5757 18.999V20.999C17.5757 21.7584 16.9601 22.374 16.2007 22.374C15.4413 22.374 14.8257 21.7584 14.8257 20.999V18.999ZM16.2007 18.374C15.8555 18.374 15.5757 18.6538 15.5757 18.999V20.999C15.5757 21.3442 15.8555 21.624 16.2007 21.624C16.5459 21.624 16.8257 21.3442 16.8257 20.999V18.999C16.8257 18.6538 16.5459 18.374 16.2007 18.374Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.4258 18.999C22.4258 18.2396 23.0414 17.624 23.8008 17.624C24.5602 17.624 25.1758 18.2396 25.1758 18.999V20.999C25.1758 21.7584 24.5602 22.374 23.8008 22.374C23.0414 22.374 22.4258 21.7584 22.4258 20.999V18.999ZM23.8008 18.374C23.4556 18.374 23.1758 18.6538 23.1758 18.999V20.999C23.1758 21.3442 23.4556 21.624 23.8008 21.624C24.146 21.624 24.4258 21.3442 24.4258 20.999V18.999C24.4258 18.6538 24.146 18.374 23.8008 18.374Z" fill="white"/>
<path d="M24.8999 24.2678C24.8999 25.9453 22.4375 27.3052 19.3999 27.3052C16.3623 27.3052 13.8999 25.9453 13.8999 24.2678C13.8999 22.5902 16.3623 22.9656 19.3999 22.9656C22.4375 22.9656 24.8999 22.5902 24.8999 24.2678Z" fill="#2B0F67"/>
<path d="M22.8999 26.2118C22.8999 27.0506 20.7806 27.3056 19.3999 27.3056C18.0192 27.3056 15.8999 27.0506 15.8999 26.2118C15.8999 25.373 18.0192 25.1357 19.3999 25.1357C20.7806 25.1357 22.8999 25.373 22.8999 26.2118Z" fill="url(#paint0_linear_3559_50906)"/>
</g>
</g>
<rect x="11.2124" y="8.87988" width="17.6845" height="7.41222" rx="3.70611" fill="#2B0F67"/>
<path d="M14.2363 8.87994C14.2363 6.83311 15.8956 5.17383 17.9424 5.17383H22.167C24.2138 5.17383 25.8731 6.83311 25.8731 8.87994C25.8731 10.9268 24.2138 12.586 22.167 12.586H17.9424C15.8956 12.586 14.2363 10.9268 14.2363 8.87994Z" fill="#2B0F67"/>
<path d="M14.2363 9.20703C18.7727 10.4456 21.3489 10.493 25.8731 9.20703V11.0081C21.5745 12.5016 18.8534 12.4343 14.2363 11.0081V9.20703Z" fill="#4C3380"/>
</g>
</g>
<defs>
<filter id="filter0_i_3559_50906" x="0" y="0" width="45.485" height="45.485" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="5.48503" dy="5.48503"/>
<feGaussianBlur stdDeviation="4.11377"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_3559_50906"/>
</filter>
<filter id="filter1_d_3559_50906" x="-0.970059" y="4.51497" width="41.9401" height="41.9401" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="5.48503"/>
<feGaussianBlur stdDeviation="5.48503"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3559_50906"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3559_50906" result="shape"/>
</filter>
<filter id="filter2_f_3559_50906" x="5.03319" y="-7.70509" width="44.9429" height="58.0664" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="6.58204" result="effect1_foregroundBlur_3559_50906"/>
</filter>
<filter id="filter3_f_3559_50906" x="-2.65479" y="4.54491" width="54.5103" height="54.5098" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="8.22754" result="effect1_foregroundBlur_3559_50906"/>
</filter>
<filter id="filter4_f_3559_50906" x="-17.9401" y="-7.34051" width="64.0804" height="76.68" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="10.9701" result="effect1_foregroundBlur_3559_50906"/>
</filter>
<filter id="filter5_f_3559_50906" x="-15.9785" y="-6.57813" width="55.6958" height="58.8057" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="8.22754" result="effect1_foregroundBlur_3559_50906"/>
</filter>
<filter id="filter6_f_3559_50906" x="7.87337" y="5.21223" width="20.4764" height="17.7347" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3.56527" result="effect1_foregroundBlur_3559_50906"/>
</filter>
<linearGradient id="paint0_linear_3559_50906" x1="19.3999" y1="25.1357" x2="19.3999" y2="27.3056" gradientUnits="userSpaceOnUse">
<stop stop-color="#E555DB"/>
<stop offset="1" stop-color="#8E3188"/>
</linearGradient>
<clipPath id="clip0_3559_50906">
<rect width="40" height="40" rx="20" fill="white"/>
</clipPath>
<clipPath id="clip1_3559_50906">
<rect width="12.3974" height="13.8559" fill="white" transform="translate(13.8999 14.1787)"/>
</clipPath>
</defs>
</svg>



    </div>
  )
}

export default Chatboticon