import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Bitcoin() {
  const [btcData, setBtcData] = useState({
    marketPrice: 0,
    openInterest: 0,
    marketSkew: { long: 0, short: 0 },
    fundingRate: 0,
    fundingVelocity: 0,
    change24h: 0,
    high24h: 0,
    low24h: 0
  });

  useEffect(() => {
    const fetchCoinData = async () => {
      try {
    
        const priceOptions = {
          method: 'GET',
          url: 'https://api.coingecko.com/api/v3/coins/bitcoin',
          params: { localization: 'false', tickers: 'true', market_data: 'true', community_data: 'false', developer_data: 'false' },
          headers: {
            Accept: 'application/json',
            'x-cg-demo-api-key': 'CG-QxQpsAygS84aHmRFdBU8XnFV'
          }
        };

  
        const bybitOptions = {
          method: 'GET',
          url: 'https://api.bybit.com/v5/market/tickers',
          params: { category: 'linear', symbol: 'BTCUSDT' }
        };

        // Fetch data from both sources
        const [priceResponse, bybitResponse] = await Promise.all([
          axios.request(priceOptions),
          axios.request(bybitOptions)
        ]);

        
        const marketData = priceResponse.data.market_data;
        const bybitData = bybitResponse.data.result.list[0];

        setBtcData({
          marketPrice: marketData.current_price.usd,
          openInterest: bybitData.openInterest || 0, 
          marketSkew: {
            long: bybitData.longRate ? parseFloat(bybitData.longRate) * 100 : 50,
            short: bybitData.shortRate ? parseFloat(bybitData.shortRate) * 100 : 50
          },
          fundingRate: bybitData.fundingRate ? parseFloat(bybitData.fundingRate) * 100 : 0,
          fundingVelocity: bybitData.fundingRateVelocity ? parseFloat(bybitData.fundingRateVelocity) : 0,
          change24h: marketData.price_change_percentage_24h,
          high24h: marketData.high_24h.usd,
          low24h: marketData.low_24h.usd
        });

      } catch (error) {
        console.error('Error fetching data:', error.response?.data || error);
    
        setBtcData(prevState => ({
          ...prevState,
          error: 'Failed to fetch Bitcoin data'
        }));
      }
    };

    
    fetchCoinData();


    const interval = setInterval(fetchCoinData, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full bg-white mt-3 p-[16px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
      
      <div className="flex gap-[20px]">
        <table className="w-full">
          <thead>
            <tr className="w-full text-[#8A8A8A] text-[14px]">
              <td>MARKET PRICE</td>
              <td>OPEN INTEREST</td>
              <td>MARKET SKEW</td>
              <td>FUNDING RATE/VELOCITY</td>
              <td>24H CHANGE</td>
              <td>24H HIGH</td>
              <td>24H LOW</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="flex items-center gap-1">
                  <div className="text-[#5DBE89]">${btcData.marketPrice.toLocaleString()}</div>
                </div>
              </td>
              <td>${(btcData.openInterest / 1_000_000).toFixed(2)}M</td>
              <td>
                <span className="text-[#5DBE89]">{btcData.marketSkew.long.toFixed(1)}%</span>
                <span className="mx-1 text-[#8A8A8A]">/</span>
                <span className="text-[#EB4245]">{btcData.marketSkew.short.toFixed(1)}%</span>
              </td>
              <td>{btcData.fundingRate.toFixed(4)}% / {btcData.fundingVelocity.toFixed(4)}%</td>
              <td>
                <span className={btcData.change24h > 0 ? 'text-[#5DBE89]' : 'text-[#EB4245]'}>
                  {btcData.change24h.toFixed(2)}%
                </span>
              </td>
              <td>${btcData.high24h.toLocaleString()}</td>
              <td>${btcData.low24h.toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Bitcoin;