import { Outlet } from "react-router-dom";
import { Header } from "../Components/Header";
import Chatbot from "../Components/Chatbot/Chatbot";

export const MainLayouts = () => {
  return (
    <div className="flex flex-col w-full h-full">
      <Header />
      <Outlet />
      <Chatbot />
    </div>
  );
};
