import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';

const TypeWriter = ({ text, speed = 40, onComplete, isRestarting = false, isMessageCompleted = false }) => {
  const [displayedText, setDisplayedText] = useState(isMessageCompleted ? text : '');
  const [currentIndex, setCurrentIndex] = useState(isMessageCompleted ? text.length : 0);
  const [isComplete, setIsComplete] = useState(isMessageCompleted);
  const spanRef = useRef(null);

  // Memoize the parsing function
  const parseLinks = useCallback((text) => {
    const parts = [];
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    let lastIndex = 0;
    let match;

    while ((match = linkRegex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        parts.push(text.slice(lastIndex, match.index));
      }

      parts.push(
        
        <a
key={match.index}
href={match[2]}
target="_blank"
rel="noopener noreferrer"
className="text-blue-600 hover:underline"
>
{match[1]}
</a>
      );

      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }

    return parts;
  }, []);

  // Memoize the content rendering
  const renderContent = useCallback((text) => {
    return text.split("\n").map((line, i) => (
      <p key={i} className="text-sm leading-relaxed">
        {parseLinks(line.replaceAll("**", ""))}
      </p>
    ));
  }, [parseLinks]);

  // Modified reset effect
  useEffect(() => {
    if (isMessageCompleted) {
      setDisplayedText(text);
      setCurrentIndex(text.length);
      setIsComplete(true);
      return;
    }

    if (!isComplete || isRestarting) {
      setDisplayedText('');
      setCurrentIndex(0);
      setIsComplete(false);
    }
  }, [text, isComplete, isRestarting, isMessageCompleted]);

  // Handle typing effect
  useEffect(() => {
    if (currentIndex >= text.length) {
      if (!isComplete) {
        setIsComplete(true);
        onComplete?.(); 
      }
      return;
    }

    const timeout = setTimeout(() => {
      setDisplayedText(text.slice(0, currentIndex + 1));
      setCurrentIndex(prev => prev + 1);
    }, speed);

    return () => clearTimeout(timeout);
  }, [currentIndex, text, speed, onComplete, isComplete]);

  // Add new effect for auto-scrolling
  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [displayedText]);

  const content = useMemo(() => renderContent(displayedText), [displayedText, renderContent]);

  // If message is completed or already typed, render full text immediately
  if (isMessageCompleted || (isComplete && !isRestarting)) {
    return <div className="whitespace-pre-wrap">{renderContent(text)}</div>;
  }

  return (
    <div className="whitespace-pre-wrap">
      {content}
      {!isComplete && <span ref={spanRef} className="animate-pulse">▋</span>}
    </div>
  );
};

export default TypeWriter;
