import React from 'react'

function DownIcon() {
  return (
    <div>   
    <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.7915 9.625L1.5415 1.375"
      stroke="#DB1F22"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.79128 3.73083V9.625L3.89795 9.62417"
      stroke="#DB1F22"
      stroke-width="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg></div>
  )
}

export default DownIcon